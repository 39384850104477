import React, { forwardRef } from "react";
import { Select as SelectChakra, Box } from "@chakra-ui/react";
import { countries } from "countries-list";

export const Country = forwardRef(({ hasValue, ...props }, ref) => {
  const countryOptions = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: country.name,
  }));

  return (
    <Box
      sx={{
        ".chakra-select": {
          paddingTop: hasValue ? "0px" : "15px",
        },
      }}
    >
      <SelectChakra {...props} ref={ref}>
        {countryOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectChakra>
    </Box>
  );
});
