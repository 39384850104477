import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const variants = {
  addToCart: definePartsStyle({
    tab: {
      color: "blackAlpha.600",
      margin: 0,
      padding: 0,
      fontSize: 16,
      fontWeight: "bold",
      textWrap: "nowrap",
      letterSpacing: "tight",
      _selected: {
        color: "black",
        textDecoration: "underline",
      },
    },
    tablist: {
      paddingBottom: 2,
      gap: 6,
    },
    tabpanel: {
      fontSize: 14,
      fontWeight: 500,
      margin: 0,
      marginTop: 3,
      padding: 0,
    },
  }),
};

export const Tabs = defineMultiStyleConfig({ variants });
