import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Flex,
  ButtonGroup,
  Button,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useKeenSlider } from "keen-slider/react";
import { motion, useAnimation } from "framer-motion";

export const UseCase = ({ slidesContent, ...props }) => {
  const containerButtonsRef = useRef(null);
  const containerRef = useRef(null);
  const buttonGroupRef = useRef(null);
  const buttonGroupAnimation = useAnimation();
  const [isLoaded, setIsLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    animationStarted(event) {
      const item = buttonGroupRef?.current?.children[event.animator.targetIdx];
      const itemLeft = item.offsetLeft ?? 0;
      const firstLeft = buttonGroupRef?.current?.children[0].offsetLeft ?? 0;
      const scrollTo = Math.max(
        0,
        Math.min(
          itemLeft -
            firstLeft -
            (containerRef.current.offsetWidth / 2 - item.offsetWidth / 2),
          buttonGroupRef.current.offsetWidth - containerRef.current.offsetWidth
        )
      );

      buttonGroupAnimation.stop();
      buttonGroupAnimation.start({
        x: -scrollTo,
        transition: { type: "spring", stiffness: 300, damping: 30 },
      });
      for (
        let index = 0;
        index < buttonGroupRef.current.children.length;
        index++
      ) {
        if (index === event.animator.targetIdx) {
          buttonGroupRef.current.children[index].dataset.active = true;
        } else {
          buttonGroupRef.current.children[index].removeAttribute("data-active");
        }
      }
    },
  });

  useEffect(() => {
    if (instanceRef.current) {
      setIsLoaded(true);
      instanceRef.current.update();
    }
  }, [instanceRef, slidesContent]);

  return (
    <Box ref={containerRef}>
      <Flex justifyContent={"center"}>
        <Box ref={containerButtonsRef} width="fit-content" maxWidth="100%">
          <ButtonGroup
            as={motion.div}
            drag="x"
            dragConstraints={containerButtonsRef}
            whileTap={{ cursor: "grabbing" }}
            ref={buttonGroupRef}
            backgroundColor="#E8E8E8"
            padding="0.2em"
            rounded="2xl"
            marginBottom={6}
            animate={buttonGroupAnimation}
          >
            {slidesContent.map((item, index) => (
              <Button
                key={index}
                variant={"toggle"}
                userSelect={"none"}
                isActive={index === 0}
                onClick={() => {
                  instanceRef.current.moveToIdx(index);
                }}
              >
                {item.Button}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
      </Flex>
      {!isLoaded && (
        <Center h="200px">
          <Spinner size="xl" />
        </Center>
      )}
      <Flex
        {...props}
        ref={sliderRef}
        className="keen-slider"
        overflow="hidden"
        userSelect="none"
        marginX={{ base: -4, md: 0 }}
        visibility={isLoaded ? "visible" : "hidden"}
      >
        {slidesContent.map((slide, index) => {
          return (
            <Box
              key={index}
              className="keen-slider__slide"
              position="relative"
              width="100%"
              height="100%"
            >
              {slide.Slide}
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};
