import React, { createContext, useContext, useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Trigger,
  Modal as ModalCustomContent,
} from "components/composed/modal";

const ModalContext = createContext(null);

export const ModalProvider = ({ children, content = [] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [identifier, setIdentifier] = useState(undefined);
  const [forceTrigger, setForceTrigger] = useState(0);

  const configuration =
    content.find((node) => node.identifier === identifier) ?? {};

  const { modal } = configuration?.metadata ?? {};

  let modalSize = { base: "full", md: "cinema" };
  if (modal?.size === "minimal") {
    modalSize = { base: "full", md: "lg" };
  }

  // Setup global functions
  if (typeof window !== "undefined") {
    window.launchModal = (identifier, force = false) => {
      setIdentifier(identifier);
      if (force) {
        setForceTrigger(forceTrigger + 1);
      }
    };
    window.cleanModal = (identifier) => {
      localStorage.removeItem(`modal.${identifier}.count`);
    };
    window.closeModal = () => {
      onClose();
    };
    window.openModal = () => {
      onOpen();
    };
  }
  return (
    <ModalContext.Provider value={{ onOpen, onClose }}>
      {children}
      <Trigger
        identifier={identifier}
        {...configuration?.metadata}
        modalActions={{ onOpen, onClose }}
        forceTrigger={forceTrigger}
      />
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={modalSize}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ModalCustomContent onClose={onClose} content={modal} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  return useMemo(() => modalContext, [modalContext]);
};
