import React, { useEffect } from "react";
import { Flex, Box, Button, Grid, useBreakpointValue } from "@chakra-ui/react";
import { useLocalizationModale } from "context/LocalizationContext";
import { countries } from "countries-list";
import { IconWorld, IconDrop } from "components/core/icons";
import { useKeenSlider } from "keen-slider/react";
import { useAddToCart } from "context/AddToCartContext";

export const BottomBarMobile = ({
  onOpen,
  localization,
  content,
  perView = 1,
}) => {
  const { isModalOpen } = useAddToCart();
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: perView,
    },
    loop: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (instanceRef.current) {
        instanceRef.current.next();
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [instanceRef]);

  return (
    <Grid
      templateColumns="1fr auto"
      alignItems="center"
      backgroundColor="#D4D4D4AF"
      backdropFilter="blur(9px)"
      color="black"
      zIndex={isModalOpen ? 1500 : 1300}
      position="fixed"
      bottom={0}
      width="100%"
      height={8}
      fontSize="xs"
      fontWeight="semibold"
      textAlign="center"
      paddingX={perView > 1 ? 3 : 1}
      gap={perView > 1 ? 3 : 1}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow={"hidden"}
      >
        <Flex ref={sliderRef} className="keen-slider" flex={1} width={"100%"}>
          {(content?.trusthighlights ?? []).map((highlight, index) => {
            const Icon = require("components/core/icons")[
              "Icon" + highlight.icon || "IconKPI"
            ];
            return (
              <Box key={index} className="keen-slider__slide">
                {Icon && <Icon marginRight={1} marginTop={-0.75} />}
                {highlight.text}
              </Box>
            );
          })}
        </Flex>
      </Box>

      <Box>
        <Button
          onClick={onOpen}
          variant="outline"
          width="fit-content"
          padding={1}
          paddingY={0}
          height={6}
          fontSize="xs"
          fontWeight="semibold"
        >
          <IconWorld boxSize="16px" marginRight={1} />
          {perView > 1 && countries[localization.country]
            ? countries[localization.country].name
            : localization.country}
          <IconDrop boxSize="8px" marginLeft={1} />
        </Button>
      </Box>
    </Grid>
  );
};

export const BottomBarDesktop = ({ localization, onOpen, content }) => {
  return (
    <Flex
      backgroundColor="#D4D4D4AF"
      backdropFilter="blur(9px)"
      color="black"
      zIndex={1200}
      position="fixed"
      bottom={0}
      width="100%"
      textAlign="center"
      justifyContent={"space-around"}
      alignItems={"center"}
      height={8}
      fontSize={"xs"}
      fontWeight={"semibold"}
      paddingX={6}
    >
      {(content?.trusthighlights ?? []).map((highlight, index) => {
        const Icon = require("components/core/icons")[
          "Icon" + highlight.icon || "IconKPI"
        ];

        return (
          <Box key={highlight.text} className="keen-slider__slide">
            {Icon && <Icon marginRight={1} marginTop={-0.75} />}
            {highlight.text}
          </Box>
        );
      })}

      <Button
        onClick={onOpen}
        variant="outline"
        width="fit-content"
        paddingX={1}
        paddingY={0}
        height={6}
        fontSize={"xs"}
        fontWeight={"semibold"}
        right={2}
      >
        <IconWorld boxSize="16px" marginRight={1} />
        {countries[localization.country] &&
          countries[localization.country].name}
        <IconDrop boxSize="8px" marginLeft={1} />
      </Button>
    </Flex>
  );
};

export const BottomBar = ({ content }) => {
  const { onOpen, localization } = useLocalizationModale();
  const perView = useBreakpointValue({ base: 1, md: 2, xl: 0 });

  if (typeof window === "undefined") {
    return null;
  }

  if (perView > 0) {
    return (
      <BottomBarMobile
        onOpen={onOpen}
        localization={localization}
        content={content}
        perView={perView}
      />
    );
  }
  return (
    <BottomBarDesktop
      onOpen={onOpen}
      localization={localization}
      content={content}
    />
  );
};
