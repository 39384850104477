import { Accordion } from "./Accordion";
import { Button } from "./Button";
import { Container } from "./Container";
import { Heading } from "./Heading";
import { Text } from "./Text";
import { Divider } from "./Divider";
import { Modal } from "./Modal";
import { Tabs } from "./Tabs";
import { Input } from "./Input";
import { Select } from "./Select";

export const components = {
  Container,
  Button,
  Accordion,
  Heading,
  Text,
  Divider,
  Modal,
  Tabs,
  Input,
  Select,
};
