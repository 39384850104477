import React from "react";
import {
  Flex,
  Spacer,
  Button,
  ButtonGroup,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { Logo } from "../Logo";
import { FiChevronDown } from "react-icons/fi";
import { CartIcon } from "../icons";
import { Menu } from "../menu";

export const PopoverIcon = ({ isOpen = false }) => {
  const iconStyles = {
    transform: isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
};

export const MenuPopover = ({ title = "Menu", items = [] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger="hover"
      openDelay={0}
      closeDelay={400}
      matchWidth={true}
      isLazy={true}
    >
      <PopoverTrigger>
        <Button rightIcon={<PopoverIcon isOpen={isOpen} />}>{title}</Button>
      </PopoverTrigger>
      <PopoverContent p="2" maxW="fit-content">
        <Stack spacing="0" alignItems="stretch">
          {items.map((item, index) => (
            <Button
              key={index}
              variant="text"
              justifyContent="start"
              as={Link}
              to={item.url ?? "#"}
            >
              {item.text}
            </Button>
          ))}
        </Stack>
      </PopoverContent>
    </Popover>
  );
};

export const Desktop = ({ menuData, cartData = {} }) => {
  return (
    <Flex alignItems={"center"} height={12}>
      <Logo transform="translateY(-10%)" />
      <Spacer />
      <ButtonGroup size="sm" variant="text">
        <Menu
          menuData={menuData.filter(
            (item) => item?.metadata?.level === "primary"
          )}
          variant="popover.default"
        />
      </ButtonGroup>
      <Spacer />

      <ButtonGroup size="sm" variant="text">
        <Menu
          menuData={menuData.filter(
            (item) => item?.metadata?.level === "secondary"
          )}
          variant="popover.default"
        />
      </ButtonGroup>

      <CartIcon marginLeft={3} page={cartData?.page} />
    </Flex>
  );
};
