import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({});

const variants = {
  cinema: definePartsStyle({
    dialog: {
      maxW: "container.xl",
      maxH: "640px",
      height: "100%",
      marginX: 3,
      marginY: 24,
    },
  }),
};

export const Select = defineMultiStyleConfig({
  baseStyle,
  variants,
});
