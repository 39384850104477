import he from "he";
import { replaceWithPlaceholders } from "utils/textParser";

export const textSurchargeWithProduct = (product, text = "") => {
  const keywords = product?.sizes?.counter ?? {};
  keywords.digits = product?.digits || 1;
  keywords.maxcount = `%number:0:${(
    Math.pow(10, keywords.digits) - 1
  ).toString()}%`;
  const suffix = {
    height: "cm",
    length: "cm",
    weight: "kg",
    width: "cm",
  };

  // Replace Sizes
  text =
    text.replace(/%\w+%/g, (match) => {
      let newMatch = match.replace(/%/g, "");
      let str = match;
      if (keywords[newMatch] !== undefined) {
        str = keywords[newMatch];
      }
      if (suffix[newMatch] !== undefined) {
        str = `%unit:${suffix[newMatch]}-only:${str}%`;
      }
      return str;
    }) ?? "";

  return he.decode(text);
};

export const addWorkingDays = (days, date = undefined) => {
  if (date === undefined) {
    date = Date.now();
  }
  let result = new Date(date);
  let addedDays = 0;

  while (addedDays < days) {
    result.setDate(result.getDate() + 1);
    const dayOfWeek = result.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      addedDays++;
    }
  }

  return result;
};

export const parseSlug = (product, slug) => {
  const productURLParameters = {
    "counter.family": product?.family ?? "counter",
    "counter.model": product?.content["product"]["model"] ?? product?.model,
    "counter.variation": product?.variation,
  };

  return replaceWithPlaceholders(slug, productURLParameters);
};
