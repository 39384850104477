import React from "react";
import { MenuEntry } from "./MenuEntry";
import { MenuSection } from "./MenuSection";
import { MenuTo } from "./MenuTo";

export const Menu = ({ menuData = undefined, ...props }) => {
  if (menuData === undefined) {
    return <></>;
  }
  // If we have a list of menu, render each individuall
  if (Array.isArray(menuData) === true) {
    return menuData.map((menuItem, index) => {
      return <Menu key={index} menuData={menuItem} {...props} />;
    });
  }
  if (menuData.layout !== undefined) {
    return <MenuSection {...menuData} {...props} />;
  }
  if (menuData.page !== undefined) {
    return <MenuTo {...menuData} {...props} />;
  }
  if (menuData.url !== undefined) {
    return <MenuEntry {...menuData} {...props} />;
  }
  return <>?</>;
};
