import React from "react";
import { Lottie } from "components";
import { Box } from "@chakra-ui/react";

export const Logo = ({
  ratio = 1,
  redirectOnClickTo = undefined,
  ...props
}) => {
  return (
    <Box {...props} width={`${88 * ratio}px`} height={`${24 * ratio}px`}>
      <Lottie
        width={`${88 * ratio}px`}
        height={`${24 * ratio}px`}
        mb="3px"
        src={
          "https://smiirl-media.s3.eu-west-1.amazonaws.com/strapi/Smiirl_Logo_Black_1d1417fadf.json"
        }
        fallback="https://smiirl-media.s3.eu-west-1.amazonaws.com/strapi/Smiirl_Logo_Black_9e804d96c0.png"
        loop
        autoplay
        behavior="restart-on-click"
        redirectOnClickTo={redirectOnClickTo}
      />
    </Box>
  );
};
