import React, { createContext, useContext, useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button,
  Text,
  Grid,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { countries, continents } from "countries-list";
import { mergeWith, omitBy, isUndefined } from "lodash";
import { useEcommerce } from "context/EcommerceContext";

// Helper function to get supported locale based on country and language
export const getSupportedLocaleFromCountry = (
  country = "US",
  language = "en"
) => {
  country = country.toUpperCase();
  language = language.toLowerCase();

  const countryData = countries[country];
  if (!countryData) return "en-US";

  const languageCodes = countryData.languages;
  if (!languageCodes || languageCodes.length === 0) return "en-US";

  if (languageCodes.includes(language)) {
    return `${language}-${country}`;
  }

  return `${languageCodes[0]}-${country}`;
};

// Helper function to parse query parameters
const getQueryParams = (key) => {
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
  }
  return null;
};

// Helper function to validate localization
const validateLocalization = (localization, countries) => {
  const { language = "en", country = "US" } = localization;
  const countryData = (countries ?? []).find((c) => c.countrycode === country);
  const currency = countryData?.countrycurrency || "USD";
  return {
    language,
    country,
    ...localization,
    locale: getSupportedLocaleFromCountry(country, language),
    currency,
  };
};

// Helper function to get initial localization from localStorage, URL query, or browser
const getInitialLocalization = (countries) => {
  if (typeof window !== "undefined") {
    // Check if locale exists in URL query parameters
    const queryLocale = getQueryParams("locale");

    // If locale is found in query params, use it and store it in localStorage
    if (queryLocale) {
      const [language, country] = queryLocale.split("-");
      const initialLocalization = validateLocalization(
        {
          language: language || "en",
          country: country || "US",
        },
        countries
      );
      if (typeof localStorage !== "undefined") {
        localStorage.setItem(
          "localization",
          JSON.stringify(initialLocalization)
        );
      }
    }
  }

  // Check if locale exists in localStorage
  if (typeof localStorage !== "undefined") {
    const storedLocalization = localStorage.getItem("localization");
    if (storedLocalization) {
      return JSON.parse(storedLocalization);
    }
  }

  // Fallback to browser locale
  const browserLocale =
    typeof navigator !== "undefined" ? navigator.language || "en-US" : "en-US";
  const [language = "en", country = "US"] = browserLocale.split("-");

  return validateLocalization(
    {
      language: language || "en",
      country: country || "US",
    },
    countries
  );
};

const splitIntoColumns = (array, columns) => {
  const result = Array.from({ length: columns }, () => []);
  array.forEach((item, index) => {
    result[Math.floor(index / Math.ceil(array.length / columns))].push(item);
  });
  return result;
};

const LocalizationContext = createContext(null);

export const LocalizationProvider = ({ children, pageLocalization }) => {
  const { cart, updateOrderInformation } = useEcommerce();

  // Initialize state with localStorage or browser's locale
  const [localization, setLocalization] = useState(() =>
    getInitialLocalization(pageLocalization?.countries)
  );

  // Store localization in localStorage
  const setValidatedLocalization = (newLocalization) => {
    const validatedLocalization = validateLocalization(
      mergeWith({}, localization, omitBy(newLocalization, isUndefined)),
      pageLocalization?.countries
    );
    setLocalization(validatedLocalization);
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(
        "localization",
        JSON.stringify(validatedLocalization)
      );
    }
    return validatedLocalization;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const setAndClose = (l) => {
    return () => {
      const newLocalization = setValidatedLocalization(l);
      if (cart && cart?.shipping_country !== newLocalization.country) {
        updateOrderInformation({
          part: "shippingcountry",
          "shipping.country": newLocalization.country,
        });
      }
      onClose();
    };
  };

  const linkedPages = {};
  (pageLocalization?.localizations?.data ?? []).forEach((element) => {
    linkedPages[element.attributes.locale] = element.attributes.slug;
  });

  const countrySorted = (pageLocalization?.countries ?? []).reduce(
    (acc, country) => {
      const countryData = countries[country.countrycode] ?? {
        continent: "Other",
      };
      if (acc[countryData.continent] === undefined) {
        acc[countryData.continent] = [];
      }
      for (const language of ["fr", "en"]) {
        if (countryData?.languages?.includes(language) || language === "en") {
          acc[countryData.continent].push({
            data: countries[country.countrycode],
            targetLanguage: language,
            ...country,
          });
        }
      }
      return acc;
    },
    {}
  );

  Object.entries(countrySorted).map(([countrycode, countrydata]) => {
    countrySorted[countrycode].sort((a, b) => {
      return a.data.name.localeCompare(b.data.name);
    });
    return countrydata;
  });

  const Country = ({ country }) => {
    const language = country.targetLanguage;
    let extraProps = {};
    if (linkedPages[language]) {
      extraProps = {
        as: Link,
        to: `/${linkedPages[language]}`,
      };
    }

    return (
      <Box marginBottom={2}>
        <Button
          onClick={setAndClose({
            country: country.countrycode,
            language,
          })}
          variant={
            country.countrycode === localization.country &&
            language === localization.language
              ? "outline"
              : "ghost"
          }
          {...extraProps}
          textAlign={"left"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          height={"fit-content"}
          paddingY={2}
          paddingLeft={3}
          width="100%"
          rounded="md"
        >
          <Text
            color={
              country.countrycode === localization.country &&
              language === localization.language
                ? ""
                : "smiirl.600"
            }
          >
            {country.data?.name ?? country.countrycode}
          </Text>
          <Text fontSize="xs" fontWeight="400">
            {language} - {country.countrycurrency}
          </Text>
        </Button>
      </Box>
    );
  };

  const CountryList = ({ countries, columnCount }) => {
    const countryColumns = splitIntoColumns(countries, columnCount);
    return (
      <Grid templateColumns={`repeat(${columnCount}, 1fr)`} gap={3}>
        {countryColumns.map((row, rowIndex) =>
          row.map((country, colIndex) => (
            <Country country={country} key={`${rowIndex}-${colIndex}`} />
          ))
        )}
      </Grid>
    );
  };

  const columnCount = useBreakpointValue({
    base: 1,
    md: 3,
    lg: 5,
  });

  return (
    <LocalizationContext.Provider
      value={{
        onOpen,
        onClose,
        localization,
        validateLocalization: (localization) => {
          return validateLocalization(
            localization,
            pageLocalization?.countries
          );
        },
      }}
    >
      {children}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", md: "cinema" }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box padding={6}>
              {Object.entries(countrySorted).map(([continent, countries]) => {
                return (
                  <Box key={continent} marginBottom={12}>
                    <Text fontSize="lg" fontWeight="bold" marginBottom={3}>
                      {continents[continent] ?? continent}
                    </Text>
                    <CountryList
                      countries={countries}
                      columnCount={columnCount}
                    />
                  </Box>
                );
              })}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </LocalizationContext.Provider>
  );
};

export const useLocalizationModale = () => {
  const localization = useContext(LocalizationContext);
  return useMemo(() => localization, [localization]);
};

export const useLocalization = (forcedLocalization = {}) => {
  const { validateLocalization, localization } =
    useContext(LocalizationContext);
  return useMemo(
    () =>
      validateLocalization(
        mergeWith(
          {},
          omitBy(localization, isUndefined),
          omitBy(forcedLocalization, isUndefined)
        )
      ),
    [forcedLocalization, localization, validateLocalization]
  );
};
