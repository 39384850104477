import React from "react";
import { graphql } from "gatsby";
import { MenuPopover } from "./MenuPopover";
import { MenuAccordion } from "./MenuAccordion";
import { MenuList } from "./MenuList";

export const fragment = graphql`
  fragment MenuSection on STRAPI__COMPONENT_PAGES_MENU_SECTION {
    id
    text
    metadata
    layout {
      identifier
      id
      metadata
      entries {
        ... on STRAPI__COMPONENT_PAGES_MENU_ENTRY {
          ...MenuEntry
        }
        ... on STRAPI__COMPONENT_PAGES_MENU_TO {
          ...MenuTo
        }
      }
    }
  }
`;

export const MenuSection = ({
  id,
  text = "Menu",
  variant = "default",
  metadata = {},
  layout = [],
  ...props
}) => {
  const [variantRoot, ...variantSubLevel] = variant.split(".");
  const variantSub =
    variantSubLevel.length > 0 ? variantSubLevel.join(".") : variantRoot;
  if (variantRoot === "popover") {
    return (
      <MenuPopover
        title={text}
        variant={variantSub}
        items={layout?.entries}
        {...props}
      />
    );
  }
  if (variantRoot === "accordion") {
    return (
      <MenuAccordion
        title={text}
        variant={variantSub}
        items={layout?.entries}
        {...props}
      />
    );
  }
  if (variantRoot === "list") {
    return (
      <MenuList
        title={text}
        variant={variantSub}
        items={layout?.entries}
        {...props}
      />
    );
  }
  return <>MenuSection</>;
};
