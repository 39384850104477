import React from "react";
import { Link } from "components";
import { Button, Box, Flex, CloseButton } from "@chakra-ui/react";

export const Toast = ({ children, onCloseToast, ...props }) => {
  return (
    <Box
      backgroundColor="#000000B0"
      padding={3}
      shadow="lg"
      rounded="lg"
      color="white"
      position="relative"
      {...props}
    >
      {children}
      <Flex
        position="absolute"
        right={2}
        top={0}
        height="100%"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CloseButton onClick={onCloseToast} color="white" size="sm" />
      </Flex>
    </Box>
  );
};

export const ToastToModal = ({ text, onCloseToast, onOpenModal }) => {
  return (
    <Toast onCloseToast={onCloseToast}>
      <Button onClick={onOpenModal} variant="text">
        {text}
      </Button>
    </Toast>
  );
};

export const ToastToUrl = ({ url, text, onCloseToast }) => {
  return (
    <Toast onCloseToast={onCloseToast}>
      <Button as={Link} href={url} variant="text" onClick={onCloseToast}>
        {text}
      </Button>
    </Toast>
  );
};
