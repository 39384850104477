import React, { useEffect } from "react";
import { Box, Flex, useTheme } from "@chakra-ui/react";
import { useKeenSlider } from "keen-slider/react";
import { TwicPicture } from "components";

function ThumbnailPlugin(mainRef, slidesCount) {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    function setup() {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = (main.animator.targetIdx || 0) % slidesCount;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    }

    setup();
  };
}

export const PressSlide = ({ slidesContent, ...props }) => {
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
  });
  const theme = useTheme();
  const breakpoints = {};
  breakpoints[`(min-width: ${theme.sizes.container.md})`] = {
    slides: { perView: 4, spacing: 50 },
  };
  const [thumbnailRef, thumbnailInstanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
    breakpoints,
    slides: {
      perView: 2,
      spacing: 50,
    },
  });

  useEffect(() => {
    if (instanceRef.current && thumbnailInstanceRef.current) {
      ThumbnailPlugin(
        instanceRef,
        slidesContent.length
      )(thumbnailInstanceRef.current);
    }
  }, [instanceRef, thumbnailInstanceRef, slidesContent.length]);

  return (
    <Box overflow={"hidden"}>
      <Flex
        {...props}
        ref={thumbnailRef}
        className="keen-slider"
        overflow="hidden"
        position="relative"
        userSelect="none"
        marginBottom={6}
        sx={{
          ".active": {
            opacity: 1,
          },
        }}
      >
        {slidesContent.map((slide, index) => {
          return (
            <Box
              key={index}
              className="keen-slider__slide"
              position="relative"
              width="100%"
              height="100%"
              filter="invert(1)"
              opacity={0.3}
            >
              <TwicPicture src={slide.Thumbnail} ratio="7:4" />
            </Box>
          );
        })}
      </Flex>
      <Flex
        {...props}
        ref={sliderRef}
        className="keen-slider"
        overflow="hidden"
        position="relative"
        userSelect="none"
        alignItems="center"
      >
        {slidesContent.map((slide, index) => {
          return (
            <Box
              key={index}
              className="keen-slider__slide"
              position="relative"
              width="100%"
              height="100%"
              textAlign="center"
            >
              {slide.Slide}
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};
