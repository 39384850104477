import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { mapNumber, createPromiseArray } from "./utils";
import { HalfFlap } from "./HalfFlap";

export const Flap = forwardRef(
  ({ defaultNumber, defaultPosition, color = "default", ...props }, ref) => {
    const childRefs = useRef([]);
    const boxRef = useRef(null);
    const digitShift = useRef(0);

    const showDisplay = async (number, shift) => {
      digitShift.current = shift;
      boxRef.current.style.zIndex =
        20 +
        (mapNumber.length - 1 - ((defaultPosition + shift) % mapNumber.length));
      return createPromiseArray(childRefs.current, "showDisplay", [
        number,
        shift,
      ]);
    };

    const animateStep = async (shift) => {
      digitShift.current = shift;
      return createPromiseArray(childRefs.current, "animateStep", [shift]);
    };

    useImperativeHandle(ref, () => ({
      showDisplay,
      animateStep,
    }));

    return (
      <Box
        ref={boxRef}
        height="100%"
        width="100%"
        position="absolute"
        zIndex={
          20 +
          (mapNumber.length -
            1 -
            ((defaultPosition + digitShift.current) % mapNumber.length))
        }
      >
        <HalfFlap
          ref={(el) => (childRefs.current[0] = el)}
          type="top"
          color={color}
          defaultNumber={defaultNumber}
          defaultPosition={defaultPosition}
        />
        <HalfFlap
          ref={(el) => (childRefs.current[1] = el)}
          type="bottom"
          color={color}
          defaultNumber={defaultNumber}
          defaultPosition={defaultPosition}
        />
      </Box>
    );
  }
);
