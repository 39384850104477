import { visit } from "unist-util-visit";

const remarkPlugin = () => (tree) => {
  visit(tree, "text", (node, index, parent) => {
    const regex = /:::intro(.*?):::/gs;
    const match = regex.exec(node.value);
    if (match) {
      const content = match[1].trim();
      parent.children.splice(index, 1, {
        type: "introBox",
        data: {
          hName: "div",
          hProperties: { "data-type": "introBox" },
        },
        children: [{ type: "text", value: content }],
      });
    }
  });
  const def = [];
  visit(tree, "definition", (node, index, parent) => {
    const { identifier, title, url } = node;
    def.push({ url, title, identifier });
  });
  visit(tree, "image", (node, index, parent) => {
    const { alt, title, url } = node;
    parent.children.splice(index, 1, {
      type: "imageBox",
      data: {
        hName: "div",
        hProperties: {
          "data-type": "imageBox",
          alt,
          title,
          url: url ?? "",
        },
      },
    });
  });
  visit(tree, "imageReference", (node, index, parent) => {
    const { alt, title, identifier } = node;
    const urlImage = def.find((d) => d.identifier === identifier)?.url;
    parent.children.splice(index, 1, {
      type: "imageBox",
      data: {
        hName: "div",
        hProperties: {
          "data-type": "imageBox",
          alt,
          title,
          url: urlImage ?? "",
        },
      },
    });
  });
};

export default remarkPlugin;
