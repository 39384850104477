import React from "react";
import { Button } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useEcommerce } from "context/EcommerceContext";
import { Field } from "components/composed/forms";

export const Promocode = ({ content, onClose }) => {
  const { cart, updateOrderInformation } = useEcommerce();

  return (
    <Formik
      initialValues={{ promocode: cart?.promocode ?? "" }}
      onSubmit={(values, actions) => {
        updateOrderInformation({
          part: "promocode",
          promocode: values.promocode ?? "",
        }).then((response) => {
          actions.setSubmitting(false);
          if (response?.errors?.children?.promocode?.errors?.length > 0) {
            const errorName =
              response?.errors?.children?.promocode?.errors[0].replace(
                "form.promocode.",
                ""
              );
            const errorString = content?.field?.errors[errorName] ?? "Error";
            actions.setFieldError("promocode", errorString);
          } else {
            onClose();
          }
        });
      }}
    >
      {(props) => (
        <Form>
          <Field
            part="promocode"
            fieldContent={content?.field}
            rightComponent={
              <Button
                variant="form-inline"
                isLoading={props.isSubmitting}
                transitionDelay={0}
                type="submit"
              >
                {content?.cta}
              </Button>
            }
          />
        </Form>
      )}
    </Formik>
  );
};
