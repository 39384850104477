import React from "react";
import { Box } from "@chakra-ui/react";

export const Digits = () => {
  return (
    <Box display="none">
      <svg
        width="100%"
        viewBox="0 0 118 2204"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <symbol id="digits" viewBox="0 0 118 2204">
          <path
            d="M91.0728 77.4121C91.0728 92.3461 89.6318 107.542 80.1998 116.581C75.4838 121.166 68.8028 124.703 58.9778 124.703C49.1528 124.703 42.4718 121.166 37.7558 116.581C28.3238 107.542 26.8828 92.3461 26.8828 77.4121C26.8828 62.4781 28.3238 47.2821 37.7558 38.2431C42.4718 33.6581 49.1528 30.1211 58.9778 30.1211C68.8028 30.1211 75.4838 33.6581 80.1998 38.2431C89.6318 47.2821 91.0728 62.4781 91.0728 77.4121ZM74.4358 77.4121C74.4358 65.6221 75.8768 45.3171 58.9778 45.3171C42.0788 45.3171 43.5198 65.6221 43.5198 77.4121C43.5198 89.2021 42.0788 109.507 58.9778 109.507C75.8768 109.507 74.4358 89.2021 74.4358 77.4121Z"
            fill="white"
          />
          <path
            d="M73.2579 351H56.8828V279.343L36.8398 293.884V276.068L59.2408 259.955H73.2579V351Z"
            fill="white"
          />
          <path
            d="M87.9299 579H29.7659V565.9L65.2669 523.587C68.4109 519.919 69.9829 515.596 69.9829 511.928C69.9829 505.509 65.9219 500.531 58.4549 500.531C50.7259 500.531 46.2719 506.426 46.2719 513.631H29.5039C29.6349 497.649 39.8529 486.121 58.5859 486.121C74.1749 486.121 86.6199 495.815 86.6199 511.797C86.6199 519.919 81.7729 527.91 76.6639 534.067L51.5119 564.197H87.9299V579Z"
            fill="white"
          />
          <path
            d="M89.2403 780.931C89.2403 799.14 75.3543 808.703 58.1933 808.703C41.4253 808.703 27.8013 797.699 27.2773 780.669H43.6523C44.3073 789.577 50.3333 794.293 58.3243 794.293C66.4463 794.293 72.7343 789.315 72.7343 780.669C72.7343 771.237 65.2673 766.914 56.7523 766.914H49.9403V752.635H56.7523C65.6603 752.635 70.7694 748.967 70.7694 740.452C70.7694 733.902 65.5293 728.531 58.3243 728.531C50.2023 728.531 44.4383 732.592 44.3073 740.845H27.8013C28.0633 723.684 41.6873 714.121 58.3243 714.121C74.8303 714.121 87.4063 724.339 87.4063 740.059C87.4063 748.312 82.5594 756.696 74.3063 759.578C83.7383 762.198 89.2403 771.499 89.2403 780.931Z"
            fill="white"
          />
          <path
            d="M93.6927 1016.66H82.1647V1035H65.7897V1016.66H25.1797V1001.2L58.5847 943.955H76.4007L42.4717 1001.86H65.7897V980.504H82.1647V1001.86H93.6927V1016.66Z"
            fill="white"
          />
          <path
            d="M91.4683 1232.08C91.4683 1251.34 80.0713 1264.83 59.7663 1264.83C41.6883 1264.83 29.1123 1253.57 28.1953 1236.41H44.5703C44.9633 1245.45 51.2513 1250.29 59.8973 1250.29C70.9013 1250.29 74.8313 1242.3 74.8313 1232.48C74.8313 1222.91 70.3773 1215.97 59.8973 1215.97C54.5263 1215.97 49.1553 1218.72 46.2733 1223.31H30.1603L32.1253 1171.96H87.4073V1186.63H46.2733L45.2253 1207.46C49.4173 1203.53 55.1813 1201.56 63.3033 1201.56C81.6433 1201.56 91.4683 1214.79 91.4683 1232.08Z"
            fill="white"
          />
          <path
            d="M91.7303 1461C91.7303 1478.82 81.1193 1492.7 60.4213 1492.7C43.1293 1492.7 32.3873 1480.39 29.6363 1464.01C28.5883 1458.12 28.1953 1452.75 28.1953 1446.98C28.1953 1433.62 28.9813 1417.25 38.2823 1407.29C43.2603 1402.05 49.6793 1398.12 60.5523 1398.12C69.8533 1398.12 76.6653 1401.13 81.3813 1405.59C86.4903 1410.44 89.1103 1416.99 89.1103 1422.36H72.6043C72.3423 1419.61 71.1633 1417.12 69.4603 1415.41C67.3643 1413.19 64.2203 1412.14 59.7663 1412.14C55.4433 1412.14 52.9543 1413.71 50.7273 1415.81C45.7493 1420.39 44.4393 1428.64 44.4393 1436.64C48.5003 1431.79 56.2293 1429.95 61.7313 1429.95C81.3813 1429.95 91.7303 1442.53 91.7303 1461ZM75.0933 1460.35C75.0933 1450.91 70.2463 1443.32 60.1593 1443.32C51.3823 1443.32 46.0113 1450.78 46.0113 1460.35C46.0113 1470.3 50.7273 1478.03 60.4213 1478.03C70.2463 1478.03 75.0933 1470.96 75.0933 1460.35Z"
            fill="white"
          />
          <path
            d="M90.5498 1641.06L55.0488 1719H37.3638L72.9958 1642.63H27.8008V1627.96H90.5498V1641.06Z"
            fill="white"
          />
          <path
            d="M90.4191 1921.46C90.4191 1939.14 75.3541 1948.83 58.9791 1948.83C42.6041 1948.83 27.5391 1939.14 27.5391 1921.46C27.5391 1912.42 32.1241 1903.77 40.7701 1899.71C34.0891 1896.04 29.3731 1887.79 29.3731 1880.06C29.3731 1862.9 43.1281 1854.12 58.9791 1854.12C74.8301 1854.12 88.5851 1862.9 88.5851 1880.06C88.5851 1887.79 83.8691 1896.04 77.1881 1899.71C85.8341 1903.77 90.4191 1912.42 90.4191 1921.46ZM72.0791 1880.32C72.0791 1873.64 67.6251 1868.53 58.9791 1868.53C50.2021 1868.53 45.8791 1873.64 45.8791 1880.32C45.8791 1887 50.3331 1892.5 58.9791 1892.5C67.6251 1892.5 72.0791 1887 72.0791 1880.32ZM73.9131 1920.41C73.9131 1912.94 68.6731 1906.91 58.9791 1906.91C49.2851 1906.91 44.0451 1912.94 44.0451 1920.41C44.0451 1928.79 50.0711 1934.29 58.9791 1934.29C67.8871 1934.29 73.9131 1928.79 73.9131 1920.41Z"
            fill="white"
          />
          <path
            d="M90.0272 2127.84C90.0272 2141.2 89.2412 2157.58 79.9402 2167.53C74.9622 2172.77 68.5432 2176.7 57.6702 2176.7C48.3692 2176.7 41.5572 2173.69 36.8412 2169.24C31.7322 2164.39 29.1122 2157.84 29.1122 2152.47H45.6182C45.8802 2155.22 47.0592 2157.71 48.7622 2159.41C50.8582 2161.64 54.0022 2162.69 58.4562 2162.69C62.7792 2162.69 65.2682 2161.11 67.4952 2159.02C72.4732 2154.43 73.7832 2146.18 73.7832 2138.19C69.7222 2143.04 61.9932 2144.87 56.4912 2144.87C36.8412 2144.87 26.4922 2132.29 26.4922 2113.82C26.4922 2096.01 37.1032 2082.12 57.8012 2082.12C75.0932 2082.12 85.8352 2094.44 88.5862 2110.81C89.6342 2116.71 90.0272 2122.08 90.0272 2127.84ZM72.2112 2114.48C72.2112 2104.52 67.4952 2096.79 57.8012 2096.79C47.9762 2096.79 43.1292 2103.87 43.1292 2114.48C43.1292 2123.91 47.9762 2131.51 58.0632 2131.51C66.8402 2131.51 72.2112 2124.04 72.2112 2114.48Z"
            fill="white"
          />
        </symbol>
      </svg>
    </Box>
  );
};
