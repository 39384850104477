import React from "react";
import { Layout } from "./layout";
import "focus-visible/dist/focus-visible";
import { isString } from "lodash";

const wrapPageElement = ({ element, props }) => {
  if (props?.pageContext) {
    if (isString(props.pageContext.Metadata)) {
      try {
        props.pageContext.Metadata = JSON.parse(props.pageContext.Metadata);
      } catch (e) {
        console.error("Error parsing pageContext.Metadata:", e);
      }
    }
  }
  return <Layout {...props}>{element}</Layout>;
};

export default wrapPageElement;
