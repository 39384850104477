export const styles = {
  global: {
    "html, body": {
      fontSize: { base: "16px", md: "17px" },
      _dark: {
        bg: "transparent",
      },
      bg: "transparent",
      position: "relative",
      textUnderlineOffset: "2px",
    },
    ".js-focus-visible :focus:not([data-focus-visible-added])": {
      outline: "none",
      boxShadow: "none",
    },
  },
};
