export const createPromiseArray = (
  elementArray,
  functionName = "none",
  args = []
) => {
  const promises = elementArray.map((element) => {
    if (element && typeof element[functionName] === "function") {
      return element[functionName](...args);
    }
    return Promise.resolve();
  });
  return Promise.all(promises);
};

export const mapNumber = [
  { top: 0, bottom: 1 },
  { top: -1, bottom: 0 },
  { top: 1, bottom: 2 },
];

export const waitForNextAnimationFrame = () =>
  new Promise((resolve) => requestAnimationFrame(resolve));

export const makeDigit = (number, size) => {
  const EMPTY_DIGIT = 10;
  let digits = number.toString().split("").map(Number);
  if (digits.length > size) {
    digits = digits.slice(digits.length - size);
  }
  while (digits.length < size) {
    digits.unshift(EMPTY_DIGIT);
  }
  return digits;
};

export const modulo = 11;
