import React from "react";
import { Box } from "@chakra-ui/react";
import { TwicImg } from "components";

export const BackgroundImage = ({ url, alt, ...props }) => (
  <Box
    position="absolute"
    width="100%"
    height="100%"
    top="50%"
    left="50%"
    transform="translate(-50%, -50%)"
    objectFit="cover"
    zIndex={-5}
    sx={{
      ".twic-i": {
        width: "100%",
        height: "100%",
        zIndex: -1,
      },
    }}
    {...props}
  >
    <TwicImg
      src={url}
      mode="cover"
      ratio="none"
      placeholder="preview"
      alt={alt}
    />
  </Box>
);
