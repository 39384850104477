import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  overlay: {
    bg: "blackAlpha.800",
  },
  dialog: {
    borderRadius: "xl",
    bg: `white`,
    overflow: "hidden",
  },
  body: {
    padding: 0,
    margin: 0,
  },
  closeButton: {
    zIndex: 100,
    backgroundColor: "whiteAlpha.600",
    borderRadius: "full",
    width: 7,
    height: 7,
  },
});

const sizes = {
  cinema: definePartsStyle({
    dialog: {
      maxW: "container.xl",
      maxH: "640px",
      height: "100%",
      marginX: 3,
      marginY: 24,
    },
  }),
};

export const Modal = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
