import React from "react";
import {
  TwicPicture as TwicPictureBasis,
  TwicImg as TwicImgBasis,
  TwicVideo as TwicVideoBasis,
} from "@twicpics/components/react";
import { assetURL } from "utils/assets";
import { useTheme } from "@chakra-ui/react";

export const TwicPicture = ({ sizes = undefined, src = "", ...props }) => {
  const theme = useTheme();

  if (sizes !== undefined) {
    sizes = sizes.replace(/@(\w+)/g, (match, p1) => {
      const size = theme.sizes.container[p1];
      return size ? `(min-width: ${size})` : match;
    });
  }

  return (
    <TwicPictureBasis src={assetURL(src, "twic")} sizes={sizes} {...props} />
  );
};

export const TwicImg = ({ src = "", ...props }) => {
  return <TwicImgBasis src={assetURL(src, "twic")} {...props} />;
};

export const TwicVideo = ({ src = "", ...props }) => {
  return <TwicVideoBasis src={assetURL(src, "twic")} {...props} />;
};
