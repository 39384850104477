import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Icon,
  Flex,
  AspectRatio,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import {
  Gallery,
  TwicImg,
  Markdown,
  CounterSelector,
  ProductPrice,
  ReassuranceBanner,
  TrustpilotBox,
} from "components";
import { isString } from "lodash";
import { useEcommerce } from "context/EcommerceContext";

const AddToCartContext = createContext(null);

const ProductContent = ({ product, onModalClose = undefined }) => {
  const { addItemToCart } = useEcommerce();

  if (!product) {
    return null;
  }

  const productContent = product?.content || [];
  const productData = productContent["product"] ?? {};
  const productGallery = productContent["product.gallery"] ?? [];
  const productSections = productData?.addToCart;

  const slidesContent = productGallery.map((slide, index) => ({
    Slide: (
      <Box
        key={index}
        width={"100%"}
        height={"100%"}
        sx={{
          ".twic-i": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <TwicImg
          mode="cover"
          ratio="none"
          placeholder="none"
          src={slide?.image}
        />
      </Box>
    ),
  }));

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      height={{ base: "auto", md: "100%" }}
    >
      <AspectRatio
        ratio={1}
        width="100%"
        maxWidth={{ base: "none", md: "640px" }}
        position={"relative"}
      >
        <Box width="100%" height="100%">
          <Gallery slidesContent={slidesContent} width="100%" />
        </Box>
      </AspectRatio>
      <Flex
        flexGrow={1}
        direction="column"
        padding={{ base: 4, md: 6 }}
        gap={{ base: 4, md: 6 }}
        height="100%"
      >
        <Heading as="h2" size={{ base: "lg", md: "xl" }}>
          {productData?.titles?.model}
        </Heading>
        <Flex justifyContent={"space-between"}>
          <CounterSelector />
          <ProductPrice product={product} variant="addToCart" />
        </Flex>
        <Flex display={{ base: "none", md: "flex" }}>
          <ReassuranceBanner product={product} />
        </Flex>
        <Tabs
          variant="addToCart"
          flexGrow={1}
          display={"flex"}
          flexDirection={"column"}
          marginBottom={{ base: 24, md: 0 }}
        >
          <TabList overflowX="auto">
            {productSections &&
              Object.keys(productSections).map((sectionId, index) => (
                <Tab key={index}>{productSections[sectionId]}</Tab>
              ))}
          </TabList>
          <TabPanels flex="1" position="relative">
            {productSections &&
              Object.keys(productSections).map((sectionId, index) => {
                const section = productContent[`product.${sectionId}`];
                if (section && isString(section)) {
                  return (
                    <TabPanel
                      key={index}
                      position={{ base: "relative", md: "absolute" }}
                      height="100%"
                      width="100%"
                      overflowY="auto"
                    >
                      <Markdown markdown={section} />
                    </TabPanel>
                  );
                }
                if (section?.trustpilot !== undefined) {
                  return (
                    <TabPanel
                      key={index}
                      height="100%"
                      {...(section?.props ?? {})}
                    >
                      <TrustpilotBox {...(section?.trustpilot ?? {})} />
                    </TabPanel>
                  );
                }
                return <TabPanel key={index}>{sectionId || "none"}</TabPanel>;
              })}
          </TabPanels>
        </Tabs>
        <Box
          position={{ base: "fixed", md: "unset" }}
          bottom={0}
          left={0}
          width="100%"
          backgroundColor="white"
          paddingX={{ base: 4, md: 0 }}
          paddingTop={{ base: 1, md: 0 }}
          paddingBottom={{ base: 9, md: 0 }}
        >
          <Button
            variant="solid-full"
            leftIcon={<Icon as={FiPlus} boxSize={6} />}
            onClick={() => {
              addItemToCart(product.reference, { redirectToCart: true });
              onModalClose();
            }}
          >
            {productContent[`addToCart.categories`]?.cta ??
              "addToCart.Categories.cta"}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export const AddToCartProvider = ({ children, pageContext }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { productStore = [], Metadata } = pageContext;
  const [productReference, setProductReference] = useState(
    Metadata?.productReference ?? productStore[0]?.reference ?? null
  );
  const product = productStore.find(
    (product) =>
      product.reference.toLowerCase() === productReference.toLowerCase()
  );

  const onOpenProduct = (reference) => {
    return () => {
      setProductReference(reference);
      onOpen();
    };
  };

  useEffect(() => {
    if (
      Metadata?.productReference &&
      productReference !== Metadata.productReference
    ) {
      setProductReference(Metadata.productReference);
    }
  }, [Metadata?.productReference]); // eslint-disable-line react-hooks/exhaustive-deps

  // Event listener for adding a product to the cart (via GTM for example)
  useEffect(() => {
    if (window === null) {
      return;
    }
    const handleModalEvent = (e) => {
      onOpen();
    };
    const handleProductEvent = (e) => {
      setProductReference(e.detail.reference);
    };

    window.addEventListener("addToCart", handleModalEvent);
    window.addEventListener("selectProduct", handleProductEvent);
    return () => {
      window.removeEventListener("addToCart", handleModalEvent);
      window.addEventListener("selectProduct", handleProductEvent);
    };
  }, [onOpen]);

  return (
    <AddToCartContext.Provider
      value={{
        onOpenProduct,
        setProductReference,
        product,
        productStore,
        onOpen,
        onClose,
        isModalOpen: isOpen,
      }}
    >
      {children}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", md: "cinema" }}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent height="100%">
          <ModalCloseButton />
          <ModalBody>
            <ProductContent product={product} onModalClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </AddToCartContext.Provider>
  );
};

export const useAddToCart = () => {
  const addToCart = useContext(AddToCartContext);
  return useMemo(() => addToCart, [addToCart]);
};

export const useAddToCartProduct = () => {
  const { product } = useContext(AddToCartContext);
  return useMemo(() => product, [product]);
};
