import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import { Box } from "@chakra-ui/react";
import flapImageInstagram from "/src/images/flap-instagram.png";
import flapImageFacebook from "/src/images/flap-facebook.png";
import flapImageBlack from "/src/images/flap-black.png";
import { motion, useAnimation } from "framer-motion";
import { waitForNextAnimationFrame, modulo, mapNumber } from "./utils";

const flapImage = {
  ig: flapImageInstagram,
  fb: flapImageFacebook,
  default: flapImageBlack,
};
const speed = 0.14;

const animationConfiguration = {
  top: {
    rotateX: [0, -90, -90, 0],
    translateY: ["0%", "0.5%", "0.5%", "0%"],
    opacity: [1, 1, 0, 0],
  },
  bottom: {
    rotateX: [0, -90, -90, 0],
    opacity: [0, 0, 1, 1],
    boxShadow: [
      "0px 6px 12px -1px rgba(0,0,0,0)",
      "0px 6px 12px -1px rgba(0,0,0,1)",
      "0px 6px 12px -1px rgba(0,0,0,1)",
      "0px 6px 12px -1px rgba(0,0,0,0)",
    ],
  },
  topContainer: {
    boxShadow: [
      "inset 0px 6px 6px -3px rgba(0,0,0,0.8)",
      "inset 0px 6px 6px -3px rgba(0,0,0,0.4)",
      "inset 0px 6px 6px -3px rgba(0,0,0,0.4)",
      "inset 0px 6px 6px -3px rgba(0,0,0,0)",
    ],
  },
};

const firstValues = Object.entries(animationConfiguration).reduce(
  (acc, [key, value]) => {
    const valueArray = Object.entries(value).reduce(
      (accAttribute, [attribute, attributeValueArray]) => {
        accAttribute[attribute] = attributeValueArray[0];
        return accAttribute;
      },
      {}
    );
    acc[key] = valueArray;
    return acc;
  },
  {}
);

const lastValues = Object.entries(animationConfiguration).reduce(
  (acc, [key, value]) => {
    const valueArray = Object.entries(value).reduce(
      (accAttribute, [attribute, attributeValueArray]) => {
        accAttribute[attribute] =
          attributeValueArray[attributeValueArray.length - 1];
        return accAttribute;
      },
      {}
    );
    acc[key] = valueArray;
    return acc;
  },
  {}
);

export const HalfFlap = forwardRef(
  (
    {
      defaultNumber = 0,
      defaultPosition = 0,
      type = "top",
      color = "default",
      ...props
    },
    ref
  ) => {
    const flapAnimation = useAnimation();
    const containerAnimation = useAnimation();
    const number = useRef(defaultNumber);
    const svgRef = useRef(null);
    const divRef = useRef(null);
    const digitShift = useRef(0);
    const isTop = type === "top";
    const isMounted = useRef(false);

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    const showDisplay = async (digitNumber = 0, digitShiftPosition = 0) => {
      // Save current values
      digitShift.current = digitShiftPosition;
      number.current = digitNumber;
      // Initalize
      const digitPosition =
        (defaultPosition + digitShiftPosition) % mapNumber.length;
      const values = digitPosition === 1 ? lastValues : firstValues;
      // Start Animation
      await waitForNextAnimationFrame();
      if (!isMounted.current) return false;
      flapAnimation.set(values[type]);
      if (values[type + "Container"]) {
        containerAnimation.set(values[type + "Container"]);
      }
      // Show Number
      const n =
        (modulo + number.current + mapNumber[digitPosition][type]) % modulo;
      svgRef.current.setAttribute(
        "viewBox",
        `0 ${228 * (n % modulo) + (type === "bottom" ? 76 : 0)} 118 2204`
      );
      await waitForNextAnimationFrame();
      return true;
    };

    const animateStep = async (digitShiftPosition = 0) => {
      if (!isMounted.current) return false;
      // Save current value
      digitShift.current = digitShiftPosition;
      // Initialize
      const digitPosition =
        (defaultPosition + digitShiftPosition) % mapNumber.length;
      // Start animation
      if (digitPosition === 0) {
        const flapPromise = flapAnimation.start(animationConfiguration[type]);
        const containerPromise = animationConfiguration[type + "Container"]
          ? containerAnimation.start(animationConfiguration[type + "Container"])
          : Promise.resolve();
        await Promise.all([flapPromise, containerPromise]);
      }
      await waitForNextAnimationFrame();
    };

    // Expose triggerFunction to the parent component
    useImperativeHandle(ref, () => ({
      showDisplay,
      animateStep,
    }));

    const digitPosition =
      (defaultPosition + digitShift.current) % mapNumber.length;
    const initialValues = digitPosition === 1 ? lastValues : firstValues;
    const defaultInitialNumber =
      (modulo + number.current + mapNumber[digitPosition][type]) % modulo;

    return (
      <motion.div
        ref={divRef}
        style={{
          height: "46%",
          width: "100%",
          top: isTop ? 0 : "47.25%",
          perspective: "1000px",
          transformStyle: "preserve-3d",
          position: "absolute",
          borderRadius: !isTop ? "0 0 10% 10%" : "10% 10% 0 0",
          overflow: "hidden",
          ...(initialValues[type + "Container"] ?? {}),
        }}
        animate={containerAnimation}
        transition={{
          duration: speed,
          ease: ["easeIn", "linear", "easeOut", "easeOut"],
          times: [0.0001, 0.5, 0.5001, 1],
        }}
      >
        <motion.div
          animate={flapAnimation}
          transition={{
            duration: speed,
            ease: ["easeIn", "linear", "easeOut", "easeOut"],
            times: [0, 0.5, 0.5001, 1],
          }}
          style={{
            height: "100%",
            overflow: "hidden",
            transformOrigin: isTop ? "bottom" : "top",
            ...(initialValues[type] ?? {}),
          }}
        >
          <Box
            {...props}
            backgroundImage={flapImage[color] ?? flapImage.default}
            backgroundSize="cover"
            backgroundPosition={isTop ? "top" : "bottom"}
            height="100%"
            overflow="hidden"
            borderRadius={!isTop ? "0 0 10% 10%" : "10% 10% 0 0"}
          >
            <motion.svg
              ref={svgRef}
              width="100%"
              viewBox={`0 ${
                228 * (defaultInitialNumber % modulo) +
                (type === "bottom" ? 76 : 0)
              } 118 2204`}
            >
              <use xlinkHref="#digits" viewBox="0 0 118 152" />
            </motion.svg>
          </Box>
        </motion.div>
      </motion.div>
    );
  }
);
