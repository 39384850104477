import React, { useState } from "react";
import { Text, TwicImg, RadioCardGroup } from "components";
import { Stack, Box, Flex, Button } from "@chakra-ui/react";
import { useGradient } from "hooks/useGradient";
import { textSurchargeWithProduct } from "utils/products";
import { IconPlus1 } from "components/core/icons";
import { useEcommerce } from "context/EcommerceContext";

const ProductVariationToggle = ({
  reference,
  setReference,
  productVariations,
}) => {
  const counterVariantOptions = productVariations
    .sort((a, b) => {
      return a.reference.localeCompare(b.reference);
    })
    .map((productVariant) => {
      return {
        children: (
          <Text height="29px" lineHeight="29px" letterSpacing="-0.01em">
            {textSurchargeWithProduct(
              productVariant,
              productVariant?.content?.product?.titles?.variation?.long
            )}
          </Text>
        ),
        value: productVariant.reference,
      };
    });

  return (
    <RadioCardGroup
      name="counterSize"
      defaultValue={reference}
      value={reference}
      options={counterVariantOptions}
      width="fit-content"
      overflow="hidden"
      onChange={(value) => {
        setReference(value);
      }}
      padding={"2px"}
      backgroundColor={"#1A1A1A10"}
      transform="scale(90%)"
    />
  );
};

export const ProductModelCard = ({ model, products, currency }) => {
  const bgGradient = useGradient(`smiirl.${model ?? "default"}`);
  const [reference, setReference] = useState(products[0].reference);
  const product = products.find((product) => product.reference === reference);
  const { addItemToCart } = useEcommerce();

  return (
    <Stack
      paddingX={3}
      paddingY={6}
      align="center"
      spacing={6}
      borderColor="#E8E8E8"
      borderWidth="1px"
      rounded="xl"
      position="relative"
      bgGradient={bgGradient}
      minWidth={"200px"}
    >
      <Box
        width="100%"
        height="70px"
        position="relative"
        sx={{
          ".twic-i": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <TwicImg
          mode="cover"
          ratio="none"
          placeholder="none"
          {...product?.content?.cart?.assets?.collection}
        />
      </Box>
      <ProductVariationToggle
        reference={reference}
        setReference={setReference}
        productVariations={products}
      />
      <Button
        onClick={() => addItemToCart(product.reference)}
        rightIcon={
          <Flex
            color="#000"
            backgroundColor="#FFF"
            rounded="full"
            width={3}
            height={3}
            alignItems="center"
            justifyContent="center"
          >
            <IconPlus1 boxSize={2} margin={0} />
          </Flex>
        }
      >
        <Text>{`%currency:${currency?.letter ?? "USD"}:${
          product?.prices[currency?.letter ?? "USD"] * (1 - product.discount)
        }%`}</Text>
      </Button>
    </Stack>
  );
};
