import React from "react";
import { Flex } from "@chakra-ui/react";
import { Navbar } from "./navbar";
import { Footer } from "./Footer";
import { useLayoutContext } from "context/LayoutContext";
import { AddToCartProvider } from "context/AddToCartContext";
import { LocalizationProvider } from "context/LocalizationContext";
import { EcommerceProvider } from "context/EcommerceContext";
import { ModalProvider } from "context/ModalContext";
import { isString } from "lodash";
import { BottomBar } from "./BottomBar";

const parseMetadata = (entry, level = 0) => {
  if (level > 10 || entry === undefined) {
    return;
  }
  if (isString(entry?.metadata)) {
    try {
      entry.metadata = JSON.parse(entry.metadata);
    } catch (e) {
      console.error("Error parsing entry.metadata:", e);
      entry.metadata = {};
    }
  }
  if (Array.isArray(entry?.nodes)) {
    entry.nodes.forEach((node) => {
      parseMetadata(node, level + 1);
    });
  }
  if (Array.isArray(entry?.entries)) {
    entry.entries.forEach((node) => {
      parseMetadata(node, level + 1);
    });
  }
  if (entry?.layout !== undefined) {
    parseMetadata(entry.layout, level + 1);
  }
};

export const Layout = ({ children, pageContext = {}, data = {}, ...props }) => {
  const { updateLayout, layout } = useLayoutContext();
  const { allStrapiLayout = {}, strapiPage } = data;
  const { localizations = {}, slug = "", locale = "" } = strapiPage ?? {};
  const metadata = pageContext?.Metadata;

  // Create a deep copy of allStrapiLayout before mutating it
  const allStrapiLayoutCopy = JSON.parse(JSON.stringify(allStrapiLayout));
  parseMetadata(allStrapiLayoutCopy);

  const cartData =
    allStrapiLayoutCopy?.nodes
      ?.find((node) => node.identifier === "header")
      ?.entries?.find((item) => item?.metadata?.isCartIcon === true) ?? {};

  const layoutName =
    layout?.name && layout.name !== "default"
      ? layout.name
      : pageContext?.layout?.name || "default";

  React.useEffect(() => {
    if (
      pageContext?.layout &&
      layout &&
      layout.name !== pageContext.layout.name
    ) {
      updateLayout(pageContext.layout);
    }
  }, [pageContext?.layout, layout, updateLayout]);

  if (layoutName === "none") {
    return (
      <Flex as="main" role="main" direction="column" flex="1">
        {children}
      </Flex>
    );
  }

  return (
    <EcommerceProvider cartData={cartData}>
      <LocalizationProvider
        pageLocalization={{
          localizations,
          slug,
          locale,
          countries: pageContext?.countries,
        }}
      >
        <AddToCartProvider pageContext={pageContext}>
          <ModalProvider
            content={allStrapiLayoutCopy?.nodes?.filter((node) =>
              node.identifier.includes("modal")
            )}
          >
            <Flex direction="column" flex="1">
              <Navbar
                isAbsolute={metadata?.menuPosition === "absolute"}
                menuData={
                  allStrapiLayoutCopy?.nodes?.find(
                    (node) => node.identifier === "header"
                  )?.entries
                }
              />
              <Flex as="main" role="main" direction="column" flex="1">
                {children}
              </Flex>
              {metadata?.hasBottomBar === true && (
                <BottomBar
                  content={
                    allStrapiLayoutCopy?.nodes?.find(
                      (node) => node.identifier === "bottombar"
                    )?.metadata
                  }
                />
              )}
              <Footer
                menuData={
                  allStrapiLayoutCopy?.nodes?.find(
                    (node) => node.identifier === "footer"
                  )?.entries
                }
                menuConfig={
                  allStrapiLayoutCopy?.nodes?.find(
                    (node) => node.identifier === "footer"
                  )?.metadata
                }
              />
            </Flex>
          </ModalProvider>
        </AddToCartProvider>
      </LocalizationProvider>
    </EcommerceProvider>
  );
};
