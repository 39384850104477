import { defineStyleConfig } from "@chakra-ui/react";

export const Text = defineStyleConfig({
  baseStyle: {
    whiteSpace: "pre-wrap",
  },
  variants: {
    hero: {
      fontWeight: 500,
      letterSpacing: "tight",
    },
    bigQuote: {
      fontSize: ["xl", null, "3xl"],
      fontWeight: 700,
      lineHeight: "shorter",
    },
    poem: {
      fontWeight: "medium",
      fontSize: ["2xl", null, "3xl"],
      lineHeight: "short",
    },
    focus: {
      fontSize: "lg",
      fontWeight: 550,
      letterSpacing: "-0.010em",
    },
    funFact: {
      fontSize: "md",
      fontWeight: 550,
      letterSpacing: "-0.010em",
    },
    funFactMuted: {
      fontSize: "xs",
      fontWeight: 500,
      letterSpacing: "-0.010em",
      color: "smiirl.500",
    },
    reading: {
      whiteSpace: "pre-wrap",
      marginBottom: 4,
    },
    "category-value": {
      fontWeight: 600,
      fontSize: "2xl",
    },
    "category-description": {
      fontWeight: 600,
      fontSize: "md",
    },
    reassurance: {
      fontSize: 11,
      lineHeight: 1.3,
      fontWeight: 500,
      letterSpacing: "-0.010em",
      color: "smiirl.700",
    },
  },

  // The default size and variant values*/
  defaultProps: {
    variant: "solid",
  },
});
