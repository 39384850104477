import React, { createContext, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";

// Create the context
const EcommerceContext = createContext();

const apiFetch = async (
  path,
  options = {},
  headers = {
    "Content-Type": "application/json",
  }
) => {
  let serverPath = `https://preprod-www.smiirl.com${path}`;
  if (process.env.NODE_ENV === "development") {
    serverPath = `https://local.new.smiirl.com/app_reve.php${path}`;
  }
  return fetch(serverPath, {
    credentials: "include",
    headers,
    ...options,
  });
};

export const enrichCartData = (cartData) => {
  if (!cartData) {
    return cartData;
  }
  const all = cartData?.item ?? [];
  const totalItems =
    all
      ?.filter((item) => item.item !== "shipping")
      .reduce(
        (acc, item) => acc + item.pu * item.quantity * (1 - item.discount),
        0
      ) ?? 0;
  const totalShipping =
    all
      ?.filter((item) => item.item === "shipping")
      .reduce(
        (acc, item) => acc + item.pu * item.quantity * (1 - item.discount),
        0
      ) ?? 0;
  const totalTax =
    all.reduce(
      (acc, item) =>
        acc + item.pu * item.quantity * (1 - item.discount) * (item.vat / 100),
      0
    ) ?? 0;
  return {
    ...cartData,
    summary: {
      items: totalItems,
      shipping: totalShipping,
      tax: totalTax,
      taxPercentage:
        totalItems + totalShipping > 0
          ? Math.round((totalTax / (totalItems + totalShipping)) * 1000) / 10
          : 0,
    },
  };
};

// Export the provider component
export const EcommerceProvider = ({ children, cartData = {} }) => {
  const [cart, setCart] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const setOrderData = (data) => {
    if (data.order) {
      setCart(data.order);
    }
    if (data.currency) {
      setCurrency(data.currency);
    }
    if (data.payments) {
      setPayments(data.payments);
    }
  };

  const cleanOrderData = () => {
    setCart(null);
    setCurrency(null);
    setPayments(null);
  };

  // Function to fetch the cart from the API
  const fetchCart = async () => {
    try {
      setLoading(true);
      const response = await apiFetch("/api/order");
      if (!response.ok) {
        throw new Error("Failed to fetch cart");
      }

      const data = await response.json();
      setOrderData(data);
    } catch (err) {
      console.error("Error fetching cart:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the cart on initial load
  useEffect(() => {
    fetchCart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Function to add an item to the cart
  const addItemToCart = async (reference, options = {}) => {
    const { redirectToCart = false } = options;
    try {
      setLoading(true);
      const response = await apiFetch("/api/order/items", {
        method: "PUT",
        body: JSON.stringify({ item_add: reference }),
      });
      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }
      const data = await response.json();
      setOrderData(data);
      if (redirectToCart === true && cartData?.page?.slug) {
        navigate(`/${cartData.page.slug}`);
      }
    } catch (err) {
      console.error("Error adding item to cart:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to remove an item from the cart
  const removeItemFromCart = async (reference, options = {}) => {
    const { redirectToCart = false } = options;
    try {
      setLoading(true);
      const response = await apiFetch("/api/order/items", {
        method: "PUT",
        body: JSON.stringify({ item_remove: reference }),
      });
      if (!response.ok) {
        throw new Error("Failed to remove item from cart");
      }
      const data = await response.json();
      setOrderData(data);
      if (redirectToCart === true && cartData?.page?.slug) {
        navigate(`/${cartData.page.slug}`);
      }
    } catch (err) {
      console.error("Error removing item from cart:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to update the quantity of an item
  const updateItemQuantity = async (reference, quantity, options = {}) => {
    const { redirectToCart = false } = options;
    try {
      setLoading(true);
      const response = await apiFetch("/api/order/items", {
        method: "PUT",
        body: JSON.stringify({
          item_set: reference,
          item_set_quantity: quantity,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to update item quantity");
      }
      const data = await response.json();
      setOrderData(data);
      if (redirectToCart === true && cartData?.page?.slug) {
        navigate(`/${cartData.page.slug}`);
      }
    } catch (err) {
      console.error("Error updating item quantity:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to update order information (e.g., shipping address)
  const updateOrderInformation = async (orderData = {}) => {
    const formData = new URLSearchParams();
    Object.entries(orderData).forEach(([key, value]) => {
      formData.append("item_order[" + key.split(".").join("][") + "]", value);
    });
    try {
      setLoading(true);
      const response = await apiFetch(
        "/api/order",
        {
          method: "PUT",
          body: formData,
        },
        {}
      );
      const data = await response.json();
      if (!response.ok) {
        const error = new Error("Failed to apply");
        error.response = data;
        throw error;
      }
      setOrderData(data);
      return data.order;
    } catch (err) {
      return err.response;
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch the cart from the API
  const subscribeNewsletter = async ({ email, source }) => {
    try {
      setLoading(true);
      const response = await apiFetch("/api/newsletters", {
        method: "POST",
        body: JSON.stringify({
          email,
          source,
        }),
      });
      const data = await response.json();
      return data;
    } catch (err) {
      setError(err);
      return err;
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch the cart from the API
  const sendQuote = async () => {
    try {
      setLoading(true);
      const response = await apiFetch("/api/quote");
      if (!response.ok) {
        throw new Error("Failed to fetch cart");
      }
      const data = await response.json();
      cleanOrderData();
      return data;
    } catch (err) {
      console.error("Error fetching cart:", err);
      setError(err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Function to add an item to the cart
  const createPayment = async (
    { provider, method, options } = {
      provider: "stripe",
      method: "card",
      options: undefined,
    },
    paymentOptions = {}
  ) => {
    try {
      setLoading(true);
      const response = await apiFetch("/api/order/payment", {
        method: "PUT",
        body: JSON.stringify({
          provider,
          method,
          options,
        }),
      });
      const data = await response.json();
      /*setOrderData(data);
      if (redirectToCart === true && cartData?.page?.slug) {
        navigate(`/${cartData.page.slug}`);
      }*/
      return data;
    } catch (err) {
      console.error("Create Payment:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Function to add an item to the cart
  const getPaymentState = async (
    { provider, method } = {
      provider: "stripe",
      method: "card",
    }
  ) => {
    try {
      setLoading(true);
      const response = await apiFetch("/api/order/payment/state", {
        method: "PUT",
        body: JSON.stringify({
          provider,
          method,
        }),
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Create Payment:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Provide all state and functions to the context consumers
  const value = {
    cart: enrichCartData(cart),
    payments,
    currency,
    loading,
    error,
    fetchCart,
    addItemToCart,
    removeItemFromCart,
    updateItemQuantity,
    updateOrderInformation,
    sendQuote,
    subscribeNewsletter,
    createPayment,
    getPaymentState,
  };

  return (
    <EcommerceContext.Provider value={value}>
      {children}
    </EcommerceContext.Provider>
  );
};

// Custom hook to use the EcommerceContext
export const useEcommerce = () => {
  const context = useContext(EcommerceContext);
  if (context === undefined) {
    throw new Error("useEcommerce must be used within an EcommerceProvider");
  }
  return context;
};
