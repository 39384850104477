import React, { useRef, useEffect, useCallback } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { makeDigit } from "./utils";
import { Digits } from "./Digits";
import { Digit } from "./Digit";

export const CounterSim = ({
  defaultNumber = 12345,
  name = "none",
  size = 5,
  color = "default",
}) => {
  defaultNumber = Math.abs(defaultNumber);

  const digitDisplay = useRef({
    index: -1,
    loopNext: false,
    current: {
      digits: makeDigit(defaultNumber, size),
      number: defaultNumber,
    },
    next: {
      digits: makeDigit(defaultNumber, size),
      number: defaultNumber,
    },
  });
  const digitRefs = useRef([]);

  const updateCounter = useCallback(
    async (number) => {
      if (digitDisplay.current.index !== -1) {
        return false;
      }
      // Initialize the number
      number = Math.abs(number);
      digitDisplay.current.index = 0;
      digitDisplay.current.loopNext = false;
      digitDisplay.current.next = {
        digits: makeDigit(number, size),
        number,
      };
      // Run though the digits
      while (
        digitDisplay.current.index < digitRefs.current.length &&
        digitDisplay.current.index >= 0
      ) {
        const { index, current, next, loopNext } = digitDisplay.current;
        if (loopNext === true || current.digits[index] !== next.digits[index]) {
          digitDisplay.current.loopNext = true;
          if (digitRefs.current[index]) {
            await digitRefs.current[index].updateDisplay(next.digits[index]);
          }
          digitDisplay.current.current.digits[index] = next.digits[index];
          digitDisplay.current.index++;
        } else {
          digitDisplay.current.index++;
        }
      }
      // Reset the counter
      digitDisplay.current.index = -1;
      return true;
    },
    [digitDisplay, size]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.counterEvents === undefined) {
        window.counterEvents = new EventTarget();
        window.updateCounter = async (counterName, counterNumber) => {
          return new Promise((resolve) => {
            const handleUpdatedCounter = (event) => {
              if (
                event.detail.name === counterName &&
                event.detail.number === counterNumber
              ) {
                window.counterEvents.removeEventListener(
                  "updatedCounter",
                  handleUpdatedCounter
                );
                resolve(event.detail.ignored);
              }
            };

            window.counterEvents.addEventListener(
              "updatedCounter",
              handleUpdatedCounter
            );

            window.counterEvents.dispatchEvent(
              new CustomEvent("updateCounter", {
                detail: { name: counterName, number: counterNumber },
              })
            );
          });
        };
      }

      const handleUpdateCounter = async (event) => {
        if (
          event?.detail?.name === name &&
          event?.detail?.number !== undefined
        ) {
          const ignored = !(await updateCounter(event?.detail?.number));
          window.counterEvents.dispatchEvent(
            new CustomEvent("updatedCounter", {
              detail: { name, number: event?.detail?.number, ignored },
            })
          );
        }
      };

      window.counterEvents.addEventListener(
        "updateCounter",
        handleUpdateCounter
      );

      return () => {
        window.counterEvents.removeEventListener(
          "updateCounter",
          handleUpdateCounter
        );
      };
    }
  }, [name, updateCounter]);

  const Spacer = ({ width = 0 }) => <Box width={width} height="100%" />;

  const digitSize = { flap: 118, spacerA: 24.5, spacerB: 40.5, spacerC: 319 };
  const sizeFive =
    digitSize.flap * 5 + digitSize.spacerA * 4 + digitSize.spacerC;
  const sizeSeven =
    digitSize.flap * 7 + digitSize.spacerA * 4 + digitSize.spacerB * 2;

  const digitSpaceConfig =
    size === 5
      ? [
          { digitIndex: 0, width: `${(digitSize.flap / sizeFive) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerA / sizeFive) * 100}%` },
          { digitIndex: 1, width: `${(digitSize.flap / sizeFive) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerA / sizeFive) * 100}%` },
          { digitIndex: 2, width: `${(digitSize.flap / sizeFive) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerA / sizeFive) * 100}%` },
          { digitIndex: 3, width: `${(digitSize.flap / sizeFive) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerA / sizeFive) * 100}%` },
          { digitIndex: 4, width: `${(digitSize.flap / sizeFive) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerC / sizeFive) * 100}%` },
        ]
      : [
          { digitIndex: 0, width: `${(digitSize.flap / sizeSeven) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerB / sizeSeven) * 100}%` },
          { digitIndex: 1, width: `${(digitSize.flap / sizeSeven) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerA / sizeSeven) * 100}%` },
          { digitIndex: 2, width: `${(digitSize.flap / sizeSeven) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerA / sizeSeven) * 100}%` },
          { digitIndex: 3, width: `${(digitSize.flap / sizeSeven) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerB / sizeSeven) * 100}%` },
          { digitIndex: 4, width: `${(digitSize.flap / sizeSeven) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerA / sizeSeven) * 100}%` },
          { digitIndex: 5, width: `${(digitSize.flap / sizeSeven) * 100}%` },
          { spacer: true, width: `${(digitSize.spacerA / sizeSeven) * 100}%` },
          { digitIndex: 6, width: `${(digitSize.flap / sizeSeven) * 100}%` },
        ];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      position="relative"
    >
      <Digits />
      <Flex
        position="absolute"
        right={`${(100 * 69) / 1317}%`}
        left={`${(100 * 242) / 1317}%`}
        top={`${(100 * 42) / 238}%`}
        bottom={`${(100 * 33) / 238}%`}
        justifyContent={"flex-end"}
      >
        {digitSpaceConfig.map((config, index) => {
          if (config.spacer) {
            return <Spacer key={index} width={config.width} />;
          }
          return (
            <Digit
              key={index}
              width={config.width}
              ref={(el) => (digitRefs.current[config.digitIndex] = el)}
              color={color}
              defaultNumber={
                digitDisplay.current.current.digits[config.digitIndex]
              }
            />
          );
        })}
      </Flex>
    </Box>
  );
};
