import React, { forwardRef } from "react";
import { Text as ChakraText } from "@chakra-ui/react";
import { useLocalization } from "context/LocalizationContext";
import { processReactChildren } from "utils/textParser";
import { useAddToCartProduct } from "context/AddToCartContext";

export const Text = forwardRef(
  ({ children, country, language, currency, ...props }, ref) => {
    const localizations = useLocalization({ country, language });
    const product = useAddToCartProduct();

    const processedChildren = processReactChildren(children, {
      localizations,
      product,
    });

    return (
      <ChakraText ref={ref} {...props}>
        {processedChildren}
      </ChakraText>
    );
  }
);
