import { defineStyleConfig, defineStyle } from "@chakra-ui/react";

const menu = defineStyle(() => {
  return {
    container: {
      border: "0px none",
    },
    button: {
      textDecoration: "none",
      paddingY: 1,
      paddingX: 0,
      color: "smiirl.900",
      _hover: {
        color: "smiirl.800",
        bgColor: "transparent",
      },
      "_focus, _highlighted": {
        color: "smiirl.900",
        bgColor: "transparent",
      },
      _active: {
        color: "smiirl.900",
        bgColor: "transparent",
      },
    },
    panel: {
      paddingY: 0,
    },
  };
});

const payments = defineStyle(() => {
  return {
    root: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "smiirl.900",
      borderRadius: "md",
      overflow: "hidden",
    },
    container: {
      borderWidth: "0px",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "smiirl.900",

      _last: {
        borderBottomWidth: "0px",
      },
    },
    button: {
      textDecoration: "none",
      paddingY: 1,
      paddingX: 0,
      color: "smiirl.900",
      padding: 3,
      backgroundColor: "transparent",
      _expanded: {
        backgroundColor: "smiirl.100",
        "& > .radio-icon": {
          borderWidth: 6,
        },
      },
      _hover: {
        color: "smiirl.800",
        bgColor: "transparent",
      },
      "_focus, _highlighted": {
        color: "smiirl.900",
        bgColor: "transparent",
      },
      _active: {
        color: "smiirl.900",
        bgColor: "transparent",
      },
      ".radio-icon": {
        display: "inline-block",
        width: 5,
        height: 5,
        borderRadius: "full",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "smiirl.800",
        bg: "white",
        marginRight: 3,
      },
    },
    panel: {
      paddingY: 0,
      backgroundColor: "smiirl.100",
    },
  };
});

const variants = {
  menu,
  payments,
};

export const Accordion = defineStyleConfig({ variants });
