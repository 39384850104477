import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Flex, Box } from "@chakra-ui/react";
import picoImage from "/src/images/pico-instagram.png";
import { mapNumber, modulo, createPromiseArray } from "./utils";
import { Flap } from "./Flap";

export const Digit = forwardRef(
  ({ defaultNumber = 0, width = "20%", color = "default" }, ref) => {
    const childRefs = useRef([]);
    const digitShiftPosition = useRef(0);
    const numberCurrent = useRef(defaultNumber);
    const numberTarget = useRef(defaultNumber);

    const updateDisplay = async (n) => {
      numberTarget.current = modulo + (n % modulo);
      numberCurrent.current = numberCurrent.current % modulo;
      while (numberCurrent.current < numberTarget.current) {
        await createPromiseArray(childRefs.current, "animateStep", [
          digitShiftPosition.current,
        ]);
        numberCurrent.current = numberCurrent.current + 1;
        digitShiftPosition.current =
          (digitShiftPosition.current + 1) % mapNumber.length;
        await createPromiseArray(childRefs.current, "showDisplay", [
          numberCurrent.current % modulo,
          digitShiftPosition.current,
        ]);
      }
      return true;
    };

    // Expose triggerFunction to the parent component
    useImperativeHandle(ref, () => ({
      updateDisplay,
    }));

    return (
      <Flex width={width} direction={"column"} position={"relative"}>
        <Box height="100%" position="relative">
          <img
            src={picoImage}
            style={{ width: "100%", zIndex: 100, position: "absolute" }}
            alt=""
          />
          {mapNumber.map((_, index) => (
            <Flap
              key={index}
              ref={(el) => (childRefs.current[index] = el)}
              color={color}
              defaultNumber={defaultNumber}
              defaultPosition={index}
            />
          ))}
        </Box>
      </Flex>
    );
  }
);
