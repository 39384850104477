export const assetURL = (url = "", to = "cdn") => {
  let path = null;
  const rootS3 = "https://smiirl-media.s3.eu-west-1.amazonaws.com";
  const rootCDN = "https://cdn.smiirl.com";
  if (url.includes(rootS3)) {
    path = url.replace(rootS3, "");
  } else if (url.includes(rootCDN)) {
    path = url.replace(rootCDN, "");
  } else if (url.length === 0) {
    path = "/strapi/undefined_a1ef4799cf.png";
  } else if (!url.includes("http")) {
    path = url;
  }

  // Export
  if (path !== null) {
    if (to === "twic") {
      return `/media${path}`;
    }
    return `${rootCDN}${path}`;
  }
  return url;
};
