import React from "react";
import { Heading } from "components";
import { Box } from "@chakra-ui/react";
import { Promocode, Email, VATNumber, Quote } from "./types";

export const Modal = (props) => {
  if (props?.content?.layout === "email") {
    return <Email {...props} />;
  } else if (
    ["promocode", "vatnumber", "quote"].includes(props?.content?.layout)
  ) {
    const components = {
      promocode: Promocode,
      vatnumber: VATNumber,
      quote: Quote,
    };
    const Component = components[props?.content?.layout];
    return (
      <Box padding={6}>
        <Heading as="h3" variant="modal" marginBottom={5}>
          {props?.content?.title}
        </Heading>
        <Component {...props} />
      </Box>
    );
  }
  return "Modal";
};
