import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { useLocalization } from "context/LocalizationContext";
import { isString } from "lodash";

function onVisibilityChange(element, callback) {
  if (!element || typeof callback !== "function") return;

  // Create a MutationObserver to listen to style attribute changes
  const observer = new MutationObserver(() => {
    callback(element.attributes["hidden"] === undefined);
  });

  // Start observing changes to the style attribute of the element
  observer.observe(element, {
    attributes: true, // Listen for attribute changes
  });

  // Return a cleanup function to disconnect the observer
  return () => observer.disconnect();
}

export const TrustpilotBox = (props) => {
  let defaultHeight = "75px";
  const propStyleHeight = props["data-style-height"];
  if (
    isString(propStyleHeight) &&
    propStyleHeight.startsWith("%containerHeight")
  ) {
    let height = propStyleHeight.replace(/%/g, "");
    defaultHeight = "400px";
    if (height.includes(":")) {
      defaultHeight = height.split(":")[1];
    }
  }
  const { locale, language } = useLocalization();
  const [widgetHeight, setWidgetHeight] = useState(defaultHeight);
  const ref = useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (typeof window !== "undefined" && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [widgetHeight]);

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (ref?.current?.parentElement) {
        const style = window.getComputedStyle(ref.current.parentElement);
        // Parse the padding values (they are returned as strings like "10px")
        const paddingTop = parseFloat(style.paddingTop);
        const paddingBottom = parseFloat(style.paddingBottom);
        const marginTop = parseFloat(style.marginTop);
        const marginBottom = parseFloat(style.marginBottom);

        // Calculate the height without padding
        const parentHeight = Math.floor(
          ref.current.parentElement.clientHeight -
            paddingTop -
            paddingBottom -
            marginBottom -
            marginTop -
            1
        );
        setWidgetHeight(`${parentHeight}px`);
      }
    };
    if (typeof window !== "undefined") {
      if (
        isString(propStyleHeight) &&
        propStyleHeight.startsWith("%containerHeight")
      ) {
        updateHeight();
        window.addEventListener("resize", updateHeight);
        let onVisibilityCleanup = null;
        if (ref?.current?.parentElement) {
          onVisibilityCleanup = onVisibilityChange(
            ref.current.parentElement,
            (visible) => {
              if (visible) {
                updateHeight();
              }
            }
          );
        }
        return () => {
          if (onVisibilityCleanup) {
            onVisibilityCleanup();
          }
          window.removeEventListener("resize", updateHeight);
        };
      }
    }
  }, [propStyleHeight]);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={locale}
      data-businessunit-id="589c5dca0000ff00059c5f11"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-review-languages={language}
      data-style-width="135px"
      {...props}
      data-style-height={widgetHeight}
    >
      <a
        href="https://fr.trustpilot.com/review/smiirl.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};
