import React from "react";
import { Text } from "components";
import { useLocalization } from "context/LocalizationContext";

export const Price = ({
  prices = undefined,
  price = undefined,
  country = undefined,
  language = undefined,
  currency = undefined,
  ...props
}) => {
  const { currency: defaultCurrency } = useLocalization({ country, language });
  currency = currency || defaultCurrency;
  let keyword = `$€£`;
  if (price !== undefined) {
    keyword = `%currency:${currency}:${price}%`;
  } else if (prices !== undefined && prices[currency] !== undefined) {
    keyword = `%currency:${currency}:${prices[currency]}%`;
  }

  return (
    <Text country={country} language={language} {...props}>
      {keyword}
    </Text>
  );
};
