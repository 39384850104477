import { isObject } from "lodash";

export const transformKeysToLowercase = (obj, { except = [], depth = 10 }) => {
  if (Array.isArray(obj)) {
    return obj.map((item) =>
      depth > 1
        ? transformKeysToLowercase(item, { except, depth: depth - 1 })
        : item
    );
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const lowerKey = key.toLowerCase();
      if (!except.includes(lowerKey)) {
        acc[lowerKey] =
          depth > 1
            ? transformKeysToLowercase(obj[key], {
                except,
                depth: depth - 1,
              })
            : obj[key];
      } else {
        acc[lowerKey] = obj[key];
      }
      return acc;
    }, {});
  }
  return obj;
};

export const filterKeys = (raw, { excludes = [], includes = [] } = {}) => {
  const keys = Object.keys(raw);
  let filteredKeys = null;
  if (includes.length > 0) {
    filteredKeys = keys.filter((key) => includes.includes(key));
  } else if (excludes.length > 0) {
    filteredKeys = keys.filter((key) => !excludes.includes(key));
  }
  if (filteredKeys !== null) {
    return filteredKeys.reduce((obj, key) => {
      obj[key] = raw[key];
      return obj;
    }, {});
  }
  return raw;
};

export const convertToArray = (input) => {
  if (Array.isArray(input)) {
    return input;
  }

  if (isObject(input)) {
    return Object.keys(input)
      .sort((a, b) => a - b)
      .map((key) => input[key]);
  }
  return [];
};
