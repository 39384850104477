import React from "react";
import he from "he";
import { Link } from "gatsby";
import { TwicPicture, Heading, Text } from "components";
import { AspectRatio, LinkBox, Stack, LinkOverlay } from "@chakra-ui/react";

export const ArticlePreview = ({ post, categories }) => {
  let image = "";
  if (post.Page?.Image?.url) {
    image = post.Page?.Image?.url;
  } else if (post.FeaturedImage) {
    image = post.FeaturedImage;
  }

  return (
    <LinkBox as="article" rounded="md">
      <LinkOverlay as={Link} to={`/${post.locale}/blog/${post.slug}`}>
        <Stack
          rounded="xl"
          backgroundColor="white"
          overflow="hidden"
          gap={0}
          height="100%"
        >
          <AspectRatio
            ratio={{ base: 1.25, md: 1.25 }}
            p={0}
            sx={{
              ".twic-i": {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <TwicPicture
              src={image}
              ratio="5:4, @md 5:4"
              sizes="100vw, @md 350px"
            />
          </AspectRatio>

          <Stack paddingX={{ base: 5, md: 6 }} paddingY={6} height="100%">
            <Text fontSize="sm" fontWeight={500} color="smiirl.700">
              {categories[post.Category] ?? false
                ? categories[post.Category]
                : post.Category}
            </Text>

            <Heading as="h2" size="md" fontWeight={600} height="100%">
              {he.decode(post.Title)}
            </Heading>

            <Text
              marginTop={1}
              fontSize="sm"
              fontWeight={500}
              color="smiirl.700"
            >
              {post.Date}
            </Text>
          </Stack>
        </Stack>
      </LinkOverlay>
    </LinkBox>
  );
};
