import React, { forwardRef } from "react";
import { Select as SelectChakra, Box } from "@chakra-ui/react";

export const Select = forwardRef(({ hasValue, ...props }, ref) => {
  return (
    <Box
      sx={{
        ".chakra-select": {
          paddingTop: hasValue ? "0px" : "15px",
        },
      }}
    >
      <SelectChakra {...props} ref={ref}>
        {Object.keys(props?.options ?? {}).map((key) => (
          <option key={key} value={key}>
            {props?.options[key]}
          </option>
        ))}
      </SelectChakra>
    </Box>
  );
});
