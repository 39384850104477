import React, { useState } from "react";
import { Box, Flex, Circle, HStack } from "@chakra-ui/react";
import { useKeenSlider } from "keen-slider/react";

export const Gallery = ({ slidesContent, ...props }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    slides: {
      origin: 0,
      perView: 1,
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <Flex
      {...props}
      ref={sliderRef}
      className="keen-slider"
      overflow="hidden"
      position="relative"
      userSelect="none"
      bgColor="smiirl.200"
      height="100%"
      minHeight={{ base: "100vw", md: "auto" }}
    >
      {slidesContent.map((slide, index) => {
        return (
          <Box
            key={index}
            className="keen-slider__slide"
            position="relative"
            overflow="hidden"
            width="100%"
            height="100%"
          >
            {slide.Slide}
          </Box>
        );
      })}
      {loaded && instanceRef.current && (
        <HStack
          position="absolute"
          width="full"
          justify="center"
          bottom="0"
          py="4"
        >
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <Circle
                key={idx}
                size="2"
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                bg={currentSlide === idx ? "black" : "blackAlpha.400"}
              />
            );
          })}
        </HStack>
      )}
    </Flex>
  );
};
