import React from "react";
import { TwicPicture, Heading, Text } from "components";
import { Box, ListItem, UnorderedList, OrderedList } from "@chakra-ui/react";
import { Link } from "gatsby";
import MarkdownTag from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import remarkPlugin from "plugins/remarkPlugin";
import { isString } from "lodash";

export const Markdown = ({ markdown, context }) => {
  return (
    <MarkdownTag
      remarkPlugins={[remarkGfm, remarkPlugin]}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: ({ node, ...props }) => (
          <Heading as="h1" size="2xl" py={4} {...props} />
        ),
        h2: ({ node, ...props }) => (
          <Heading as="h2" size="md" py={3} {...props} />
        ),
        h3: ({ node, ...props }) => (
          <Heading as="h3" size="md" py={2} {...props} />
        ),
        h4: ({ node, ...props }) => (
          <Heading as="h4" size="sm" py={1} {...props} />
        ),
        h5: ({ node, ...props }) => (
          <Heading as="h5" size="md" py={1} {...props} />
        ),
        h6: ({ node, ...props }) => (
          <Heading as="h6" size="xs" py={1} {...props} />
        ),
        p: ({ node, ...props }) => {
          if (props.children === undefined) {
            return null;
          }
          return <Text whiteSpace="pre-wrap" pb={4} {...props} />;
        },
        div: ({ node, children }) => {
          if (node.properties.dataType === "imageBox") {
            let { alt, imgProps = {} } = node?.properties;
            if (alt.includes("::")) {
              [alt, imgProps] = alt.split("::");
              if (isString(imgProps)) {
                try {
                  imgProps = JSON.parse(imgProps);
                } catch (e) {
                  console.error("Can parse JSON from Image Box", imgProps);
                }
              }
            }
            if (context === "blog") {
              return (
                <Box mb={6} marginX={{ base: -44, md: -16, lg: -44 }}>
                  <Box
                    maxWidth={{
                      base: "100vw",
                      md: "calc(100vw - var(--chakra-space-8))",
                    }}
                    marginX="auto"
                    maxHeight="90vh"
                    sx={{
                      ".twic-i": {
                        width: "100%",
                        height: "auto",
                        maxHeight: "90vh",
                        rounded: { base: 0, md: "lg" },
                      },
                    }}
                    overflow={"hidden"}
                    {...imgProps}
                  >
                    <TwicPicture
                      src={node?.properties?.url}
                      ratio="none"
                      alt={node?.properties?.alt}
                      mode="cover"
                      placeholder="preview"
                    />
                  </Box>
                </Box>
              );
            }
            return (
              <Box mb={4}>
                <Box
                  maxHeight="90vh"
                  sx={{
                    ".twic-i": {
                      width: "auto",
                      height: "auto",
                      maxHeight: "90vh",
                    },
                  }}
                  overflow={"hidden"}
                  {...imgProps}
                >
                  <TwicPicture
                    src={node?.properties?.url}
                    ratio="none"
                    alt={node?.properties?.alt}
                    mode="cover"
                    placeholder="preview"
                  />
                </Box>
              </Box>
            );
          }
          if (node.properties.dataType === "introBox") {
            return (
              <Text fontSize="lg" fontWeight={550} pb={9}>
                {children}
              </Text>
            );
          }
          return null;
        },
        a: ({ node, ...props }) => {
          if (
            props.href.startsWith("http://") ||
            props.href.startsWith("https://") ||
            props.href.startsWith("mailto:") ||
            props.href.startsWith("tel:")
          ) {
            return (
              <a href={props.href} target="_blank" rel="noopener noreferrer">
                {props.children}
              </a>
            );
          }
          return <Link to={props.href}>{props.children}</Link>;
        },
        ul: ({ node, ...props }) => {
          return <UnorderedList paddingLeft={1} {...props} />;
        },
        ol: ({ node, ...props }) => {
          return <OrderedList paddingLeft={1} {...props} />;
        },
        li: ({ node, ...props }) => {
          return <ListItem {...props} />;
        },
        /*
          blockquote
          br
          code
          em
          hr
          img
          pre
          strong
          del
          input
          table
          tbody
          td
          th
          thead
          tr*/
      }}
      children={markdown}
    />
  );
};
