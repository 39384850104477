import React, { forwardRef } from "react";
import { Link as GatsbyLink } from "gatsby";

export const Link = forwardRef(({ children, href, ...props }, ref) => {
  if (href === undefined) {
    return <>{children}</>;
  }
  if (href.startsWith("http")) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }
  return (
    <GatsbyLink to={href} {...props}>
      {children}
    </GatsbyLink>
  );
});
