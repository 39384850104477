import React from "react";
import {
  Flex,
  Spacer,
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  useDisclosure,
  DrawerOverlay,
  Container,
  Accordion,
} from "@chakra-ui/react";
import { Logo } from "../Logo";
import { MenuIcon, CartIcon } from "../icons";
import { Menu } from "../menu";

export const MobileDrawer = ({ mobileNavbar, menuData, ...props }) => {
  return (
    <Drawer placement="top" {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody paddingX={0} paddingTop={1} paddingBottom={4}>
          <Container>
            <Flex alignItems={"center"} height={12}>
              <Logo transform="translateY(-10%)" />
              <Spacer />
              <MenuIcon isOpen={true} onClick={mobileNavbar.onToggle} />
            </Flex>
            <Stack spacing="6" align="stretch">
              <Accordion defaultIndex={[0]} variant="menu">
                <Menu
                  menuData={menuData}
                  variant="accordion.default"
                  onClickLeaf={mobileNavbar.onClose}
                />
              </Accordion>
            </Stack>
          </Container>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const Mobile = ({ menuData = [], cartData = {} }) => {
  const mobileNavbar = useDisclosure();
  return (
    <Flex alignItems={"center"} height={12}>
      <Logo transform="translateY(-10%)" />
      <Spacer />
      <CartIcon page={cartData?.page} />
      <MenuIcon isOpen={mobileNavbar.isOpen} onClick={mobileNavbar.onToggle} />
      <MobileDrawer
        menuData={menuData}
        mobileNavbar={mobileNavbar}
        isOpen={mobileNavbar.isOpen}
        onClose={mobileNavbar.onClose}
      />
    </Flex>
  );
};
