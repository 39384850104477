import React from "react";
import { useRadio, Box, Flex } from "@chakra-ui/react";

export const RadioCard = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label" width="100%" height="100%" minHeight="30px">
      <input {...input} />
      <Flex
        {...radio}
        fontSize="sm"
        cursor="pointer"
        outline="none"
        _checked={{
          bg: "white",
          color: "black",
        }}
        height="100%"
        rounded="full"
        whiteSpace="nowrap"
        justifyContent="center"
        paddingX="12px"
      >
        {props.children}
      </Flex>
    </Box>
  );
};
