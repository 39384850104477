import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import "@fontsource-variable/inter";
import { fontSizes, fonts, lineHeights } from "./typography";
import { sizes } from "./sizes";
import { colors, gradients, defaultColorScheme } from "./colors";
import { styles } from "./styles";
import { shadows } from "./shadows";
import { components } from "./components";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme(
  {
    config,
    sizes,
    styles,
    fontSizes,
    fonts,
    lineHeights,
    shadows,
    colors: { ...colors },
    gradients: { ...gradients },
    components,
  },
  withDefaultColorScheme({ colorScheme: defaultColorScheme })
);

export default theme;
