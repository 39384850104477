import { defineStyleConfig } from "@chakra-ui/react";

export const Divider = defineStyleConfig({
  variants: {
    category: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "blackAlpha.600",
      _dark: {
        borderColor: "whiteAlpha.600",
      },
    },
    "category-spacer": {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "transparent",
      marginY: 4,
      _dark: {
        borderColor: "transparent",
      },
    },
  },
});
