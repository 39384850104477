import React from "react";
import { Flex, Button } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { useEcommerce } from "context/EcommerceContext";
import { isArray } from "lodash";
import { Field } from "components/composed/forms";
import { mapKeys } from "lodash";
import { navigate } from "gatsby";

export const Quote = ({ content, onClose }) => {
  const { cart, updateOrderInformation, sendQuote } = useEcommerce();
  const quoteValues = {
    firstname: cart?.firstname ?? "",
    familyname: cart?.familyname ?? "",
    email: cart?.email ?? "",
    vatnumber: cart?.vatnumber ?? "",
    company: cart?.company ?? "",
    street1: cart?.shipping_street1 ?? "",
    street2: cart?.shipping_street2 ?? "",
    zip: cart?.shipping_zip ?? "",
    city: cart?.shipping_city ?? "",
    state: cart?.shipping_state ?? "",
    phone: cart?.phone ?? "",
  };
  return (
    <Formik
      initialValues={{
        quote: quoteValues,
      }}
      onSubmit={(values, actions) => {
        updateOrderInformation({
          part: "quote",
          ...mapKeys(values.quote, (value, key) => `quote.${key}`),
        }).then((response) => {
          sendQuote().then((response) => {
            if (response.quotation === true) {
              content?.redirect && navigate(content.redirect);
            }
            actions.setSubmitting(false);
            onClose();
          });
        });
      }}
    >
      {(props) => (
        <Form>
          <Flex direction="column" gap={3}>
            {content.fields.map((fieldContent, index) => {
              if (isArray(fieldContent)) {
                return (
                  <Flex gap={3} key={`${index}`}>
                    {fieldContent.map((field, index2) => {
                      return (
                        <Field
                          part="quote"
                          key={`${index}-${index2}`}
                          fieldContent={field}
                          validate={(value) => {
                            if (!value) {
                              return "Required";
                            }
                          }}
                        />
                      );
                    })}
                  </Flex>
                );
              }
              return (
                <Field
                  part="quote"
                  key={`${index}`}
                  fieldContent={fieldContent}
                />
              );
            })}
            <Button
              marginTop={3}
              minHeight={12}
              variant="form-inline"
              isLoading={props.isSubmitting}
              type="submit"
            >
              {content?.cta}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
