import React, { useEffect } from "react";
import { ToastToModal, ToastToUrl } from "components";
import { useToast } from "@chakra-ui/react";

export const Trigger = ({
  identifier,
  toast,
  modal,
  modalActions,
  persist,
  forceTrigger = 0,
}) => {
  const toastLauncher = useToast();

  // Check if modal should be displayed
  useEffect(() => {
    if (identifier !== undefined) {
      let launch = true;
      const launchCount = parseInt(
        localStorage.getItem(`modal.${identifier}.count`) ?? 0
      );
      if (persist?.mode === "once" && launchCount > 0) {
        launch = false;
      }

      if (launch) {
        localStorage.setItem(
          `modal.${identifier}.count`,
          parseInt(launchCount + 1)
        );
        // No toat, launch modal
        if (toast === undefined && modal !== undefined) {
          modalActions?.onOpen();
        } else if (toast !== undefined) {
          const toastProps = {
            containerStyle: { marginBottom: 6, zIndex: 1100 },
            ...toast.props,
          };
          if (toast?.layout === "toModal" && toast.text) {
            toastProps.render = ({ onClose: onCloseToast }) => {
              const onOpenModal = () => {
                onCloseToast();
                if (typeof window !== "undefined" && window.openModal) {
                  setTimeout(window.openModal, 500);
                }
              };
              return (
                <ToastToModal
                  text={toast?.text}
                  onCloseToast={onCloseToast}
                  onOpenModal={onOpenModal}
                />
              );
            };
          } else if (toast?.layout === "toURL" && toast.text && toast.url) {
            toastProps.render = ({ onClose: onCloseToast }) => (
              <ToastToUrl
                text={toast?.text}
                url={toast.url}
                onCloseToast={onCloseToast}
              />
            );
          }
          toastLauncher(toastProps);
        }
      }
    }
  }, [identifier, forceTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
