import React, { useRef, useEffect, forwardRef } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { FastField as FieldFormik, useFormikContext } from "formik";
import { get, cloneDeep } from "lodash";
import { Input } from "./Input";
import { Select } from "./Select";
import { Country } from "./Country";

const WithRightComponent = ({ rightComponent, children }) => {
  if (rightComponent) {
    return (
      <Flex gap={3}>
        {children}
        {rightComponent}
      </Flex>
    );
  }
  return children;
};

export const computeSyncHash = (fieldContent, values) => {
  if (!fieldContent.syncFields) return "";
  const syncValues = fieldContent.syncFields.map((syncName) => {
    return get(values, syncName, "");
  });

  return JSON.stringify(syncValues);
};

export const FieldMap = forwardRef((props, ref) => {
  if (props.type === "country") {
    return <Country {...props} type="select" ref={ref} />;
  }
  if (props.type === "select") {
    return <Select {...props} ref={ref} />;
  }
  return <Input {...props} ref={ref} />;
});

export const Field = ({
  fieldContent,
  validate,
  rightComponent,
  part = "checkout",
}) => {
  const { setValues } = useFormikContext();
  const mainKey = fieldContent.name.replace(part + ".", "");
  const inputRef = useRef(null);
  const hasFocus = useRef(false);
  const syncFieldsRef = useRef(
    JSON.parse(JSON.stringify(fieldContent?.syncFields ?? []))
  );

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValues((prevValues) => {
      const oldMainValue = prevValues[part][mainKey];
      const updatedValues = cloneDeep(prevValues);

      if (typeof updatedValues[part] === "object") {
        updatedValues[part][mainKey] = newValue;
      } else if (typeof updatedValues[part] === "string") {
        updatedValues[part] = newValue;
      }
      if (fieldContent.syncFields) {
        fieldContent.syncFields
          .filter((item) => syncFieldsRef.current.includes(item))
          .forEach((syncName) => {
            const syncKey = syncName.replace(part + ".", "");
            const oldSyncValue =
              typeof prevValues[part] === "string"
                ? prevValues[part]
                : prevValues[part][syncKey];

            if (oldSyncValue === oldMainValue) {
              if (typeof updatedValues[part] === "object") {
                updatedValues[part][syncKey] = newValue;
              } else if (typeof updatedValues[part] === "string") {
                updatedValues[part] = newValue;
              }
            } else {
              syncFieldsRef.current = syncFieldsRef.current.filter(
                (item) => item !== syncName
              );
            }
          });
      }
      return updatedValues;
    }, false);
  };

  useEffect(() => {
    if (hasFocus.current) {
      inputRef.current.focus();
    }
  }, [fieldContent.syncHash]);

  return (
    <FieldFormik
      name={fieldContent?.name}
      validate={validate}
      key={fieldContent?.syncHash}
    >
      {({ field, form }) => {
        return (
          <FormControl
            key={fieldContent?.name}
            isInvalid={form.errors[fieldContent.name]}
          >
            <FormLabel
              opacity={!field.value ? 0 : 1}
              position="absolute"
              marginLeft={4}
              marginTop={!field.value ? "12px" : "5px"}
              paddingX={0.5}
              fontSize={"12px"}
              textColor="smiirl.500"
              zIndex={100}
              transitionDuration=".25s"
              transitionProperty="opacity margin-top"
            >
              {fieldContent?.label ?? fieldContent?.placeholder}
            </FormLabel>
            <WithRightComponent rightComponent={rightComponent}>
              <FieldMap
                minHeight={12}
                transitionDuration=".25s"
                transitionProperty="padding-top"
                hasValue={!field.value}
                {...field}
                placeholder={fieldContent?.placeholder}
                {...fieldContent?.inputProps}
                onChange={(e) => {
                  handleChange(e);
                  if (field.onChange) {
                    field.onChange(e);
                  }
                  if (fieldContent?.onChange) {
                    fieldContent.onChange(e);
                  }
                }}
                onFocus={(e) => {
                  if (field.onFocus) {
                    field.onFocus(e);
                  }
                  if (fieldContent?.onFocus) {
                    fieldContent.onFocus(e);
                  }
                  hasFocus.current = true;
                }}
                onBlur={(e) => {
                  if (field.onBlur) {
                    field.onBlur(e);
                  }
                  if (fieldContent?.onBlur) {
                    fieldContent.onBlur(e);
                  }
                  hasFocus.current = false;
                }}
                ref={inputRef}
              />
            </WithRightComponent>
            <FormErrorMessage>
              {form.errors[fieldContent?.name]}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </FieldFormik>
  );
};
