import React, { useCallback, useState, useEffect, useRef } from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { Box } from "@chakra-ui/react";
import { TwicPicture } from "components";
import { assetURL } from "utils/assets";
import { navigate } from "gatsby";
import { isArray } from "lodash";

export const Lottie = ({
  src = "",
  fallback = "",
  loop = false,
  autoplay = true,
  behavior = undefined,
  segments = [],
  firstFrame = undefined,
  redirectOnClickTo = undefined,
  onClick = undefined,
  ...props
}) => {
  const [dotLottie, setDotLottie] = useState(null);
  const [lottieLoaded, setLottieLoaded] = useState(false);
  const segmentIndex = useRef(0);

  useEffect(() => {
    const onLoad = () => {
      setLottieLoaded(true);
      if (dotLottie && firstFrame !== undefined) {
        dotLottie.setFrame(firstFrame);
      }
    };

    const onLoadError = () => {
      setLottieLoaded(false);
    };

    if (dotLottie) {
      dotLottie.addEventListener("load", onLoad);
      dotLottie.addEventListener("loadError", onLoadError);
    }

    return () => {
      if (dotLottie) {
        dotLottie.removeEventListener("load", onLoad);
        dotLottie.removeEventListener("loadError", onLoadError);
      }
    };
  }, [dotLottie, firstFrame]);

  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie);
  };

  const handleOnClick = useCallback(() => {
    if (dotLottie && behavior === "restart-on-click") {
      dotLottie.setFrame(0);
    } else if (dotLottie && behavior === "start-on-click") {
      dotLottie.setFrame(0);
      dotLottie.play();
    } else if (dotLottie && behavior === "play-segment-on-click") {
      if (
        isArray(segments) &&
        segments.length > 0 &&
        segments[segmentIndex.current]
      ) {
        dotLottie.setSegment(...segments[segmentIndex.current]);
        dotLottie.play();
        segmentIndex.current = (segmentIndex.current + 1) % segments.length;
      } else {
        dotLottie.setFrame(0);
      }
      dotLottie.play();
    }
    if (redirectOnClickTo) {
      navigate(redirectOnClickTo);
    }
    if (typeof onClick === "function") {
      onClick(segmentIndex.current);
    }
  }, [dotLottie, behavior, redirectOnClickTo, onClick, segments]);

  return (
    <Box
      overflow="hidden"
      {...props}
      cursor={behavior !== undefined ? "pointer" : "default"}
      sx={{
        ".twic-p>img": fallback
          ? { width: props.width, height: props.height }
          : {},
      }}
      onClick={handleOnClick}
    >
      {fallback && !lottieLoaded && (
        <TwicPicture
          src={fallback}
          ratio="none"
          mode="cover"
          placeholder="preview"
        />
      )}
      <DotLottieReact
        src={assetURL(src)}
        loop={loop}
        autoplay={autoplay}
        dotLottieRefCallback={dotLottieRefCallback}
      />
    </Box>
  );
};
