import React from "react";
import { Box, Container, useBreakpointValue } from "@chakra-ui/react";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";

export const Navbar = ({ isAbsolute = false, menuData = [] }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const cartData =
    menuData.find((item) => item?.metadata?.isCartIcon === true) ?? {};

  return (
    <Box
      as="nav"
      role="navigation"
      alignContent="center"
      position={isAbsolute ? "absolute" : "relative"}
      width={"100%"}
      paddingY={1}
      zIndex={100}
    >
      <Container>
        {isDesktop ? (
          <Desktop menuData={menuData} cartData={cartData} />
        ) : (
          <Mobile menuData={menuData} cartData={cartData} />
        )}
      </Container>
    </Box>
  );
};
