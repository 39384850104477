import React from "react";
import { Box, Icon, IconButton, Text } from "@chakra-ui/react";
import { AiOutlineShopping, AiFillShopping } from "react-icons/ai";
import { useEcommerce } from "context/EcommerceContext";
import { navigate } from "gatsby";

export const CartIcon = ({ count = 0, page = {}, ...props }) => {
  const { cart } = useEcommerce();
  count =
    count ||
    cart?.item
      .filter((item) => item.item !== "shipping")
      .reduce((acc, item) => acc + item.quantity, 0);
  return (
    <Box
      alignContent={"center"}
      onClick={() => {
        if (page?.slug) {
          navigate("/" + page?.slug);
        }
      }}
      {...props}
      position={"relative"}
      w={8}
      h={8}
      transform={"translateY(-5%)"}
    >
      <Box
        position={"absolute"}
        zIndex={100}
        cursor={"pointer"}
        w="100%"
        h="100%"
        fontSize={"12px"}
        color="white"
        textAlign={"center"}
        fontWeight={600}
        alignContent={"center"}
      >
        <Text userSelect={"none"} lineHeight={8} transform={"translateY(10%)"}>
          {count > 0 ? count : ""}
        </Text>
      </Box>
      <IconButton
        overflow={"hidden"}
        icon={
          <Icon
            as={count > 0 ? AiFillShopping : AiOutlineShopping}
            w={8}
            h={8}
          />
        }
        variant="link"
        aria-label="Shopping cart"
        color={"black"}
      />
    </Box>
  );
};
