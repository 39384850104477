import React from "react";
import { Flex } from "@chakra-ui/react";
import { Price } from "components";
import { clone } from "lodash";

export const ProductPrice = ({
  variant = "default",
  product = undefined,
  ...props
}) => {
  const { prices, discount } = product;
  const hasDiscount = discount !== null && discount > 0.001;
  const discountedPrices = clone(prices);
  Object.keys(discountedPrices).map(
    (cur) => (discountedPrices[cur] = parseFloat(prices[cur] * (1 - discount)))
  );

  if (variant === "addToCart") {
    return (
      <Flex gap={3} alignItems={"center"}>
        {hasDiscount && (
          <Price
            fontWeight="normal"
            fontSize="15px"
            opacity="0.3"
            textDecoration="line-through"
            prices={prices}
            {...props}
          />
        )}
        <Price
          fontWeight="semibold"
          fontSize="25px"
          prices={hasDiscount ? discountedPrices : prices}
          {...props}
        />
      </Flex>
    );
  }

  return (
    <>
      <Price
        fontWeight="semibold"
        fontSize="25px"
        color="White"
        prices={discountedPrices}
        {...props}
      />
      {hasDiscount && (
        <Price
          fontWeight="semibold"
          fontSize="15px"
          textDecoration="line-through"
          color="White"
          prices={prices}
          {...props}
        />
      )}
    </>
  );
};
