import React, { useRef } from "react";
import { TwicImg, Heading, Text, Toast } from "components";
import { Button, Grid, Box, GridItem } from "@chakra-ui/react";
import { useEcommerce } from "context/EcommerceContext";
import { Formik, Form } from "formik";
import { Field } from "components/composed/forms";
import { useToast } from "@chakra-ui/react";

export const Email = ({ content, onClose, ...props }) => {
  const { cart, subscribeNewsletter } = useEcommerce();
  const formikRef = useRef(null);
  const checkoutRefValues = useRef(undefined);
  const toastLauncher = useToast();

  const checkoutValues = {
    email: cart?.email ?? "",
  };
  checkoutRefValues.current = checkoutValues;

  return (
    <Grid
      width="100%"
      minHeight="100%"
      templateColumns={{ base: "1fr", md: "40% 1fr" }}
      templateRows={{ base: "repeat(2, auto)", md: "1fr" }}
    >
      <GridItem maxWidth={"100%"} maxHeight={"100vw"} overflow={"hidden"}>
        <Box
          width={"100%"}
          height={"100%"}
          sx={{
            ".twic-i": {
              width: "100%",
              height: "100%",
              minHeight: "300px",
            },
          }}
        >
          <TwicImg
            mode="cover"
            ratio="none"
            placeholder="none"
            src={content?.media}
          />
        </Box>
      </GridItem>
      <GridItem paddingY={{ base: 12, md: 12 }} paddingX={{ base: 6, md: 12 }}>
        <Heading as="h2" variant="section" textAlign="left" marginBottom={2}>
          {content?.title}
        </Heading>
        <Text variant="subSection" textAlign="left" marginBottom={6}>
          {content?.description}
        </Text>

        <Formik
          innerRef={formikRef}
          initialValues={{
            checkout: checkoutValues,
          }}
          onSubmit={(values, actions) => {
            subscribeNewsletter({
              email: values.checkout.email,
              source: content?.source ?? "modal10percent",
            }).then((response) => {
              if (response.success === true) {
                onClose();
                toastLauncher({
                  text: response.message,
                  render: ({ text, onClose: onCloseToast }) => {
                    return (
                      <Toast onCloseToast={onCloseToast} paddingX={9}>
                        {text ?? "Success"}
                      </Toast>
                    );
                  },
                  props: {
                    duration: 9000,
                    isClosable: true,
                  },
                });
              }
              actions.setSubmitting(false);
            });
          }}
        >
          {({ values, isSubmitting }) => {
            return (
              <Form>
                <Field
                  fieldContent={{
                    name: "checkout.email",
                    placeholder: "Email",
                    errors: {},
                    inputProps: {
                      type: "email",
                      required: "required",
                      autoCorrect: "off",
                      autoCapitalize: "off",
                      inputMode: "email",
                      autoComplete: "email",
                    },
                  }}
                  validate={(value) => {
                    if (!value) {
                      return "Required";
                    }
                  }}
                />
                <Button
                  variant="solid-full"
                  isLoading={isSubmitting}
                  type="submit"
                  marginTop={6}
                >
                  {content?.cta}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </GridItem>
    </Grid>
  );
};
