import React, { useRef } from "react";
import { Container, Text, Toast } from "components";
import {
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { Logo } from "./Logo";
import {
  FaInstagram,
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaXTwitter,
  FaLink,
  FaTiktok,
} from "react-icons/fa6";
import { Menu } from "./menu";
import { useLocalizationModale } from "context/LocalizationContext";
import { IconWorld, IconDrop } from "components/core/icons";
import { countries } from "countries-list";
import { Formik, Form } from "formik";
import { Field } from "components/composed/forms";
import { useEcommerce } from "context/EcommerceContext";
import { useToast } from "@chakra-ui/react";

export const Footer = ({ menuData, menuConfig = {} }) => {
  const { localization, onOpen } = useLocalizationModale();
  const { cart, subscribeNewsletter } = useEcommerce();
  const formikRef = useRef(null);
  const checkoutRefValues = useRef(undefined);
  const toastLauncher = useToast();

  const checkoutValues = {
    email: cart?.email ?? "",
  };
  checkoutRefValues.current = checkoutValues;

  return (
    <Container
      backgroundGradient={menuData?.backgroundGradient ?? `smiirl.footer`}
      as="footer"
      role="contentinfo"
    >
      <Stack
        justify="space-between"
        align="start"
        direction={"column"}
        spacing="8"
        width={"100%"}
      >
        <Stack spacing={{ base: "6", md: "8" }} align="start">
          <Logo redirectOnClickTo={menuConfig?.logoUrl} />
          <Text color="fg.accent.muted">{menuConfig?.caption}</Text>
        </Stack>
        <Divider borderColor="#0000000A" />
        <SimpleGrid columns={{ base: 1, md: 4 }} gap="8" width={"full"}>
          <Menu menuData={menuData} variant="list" />
        </SimpleGrid>
        <Divider borderColor="#0000000A" />
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="8" width={"full"}>
          <Stack spacing="4">
            <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
              {menuConfig?.newsletter?.caption}
            </Text>

            <Formik
              innerRef={formikRef}
              initialValues={{
                checkout: checkoutValues,
              }}
              onSubmit={(values, actions) => {
                subscribeNewsletter({
                  email: values.checkout.email,
                  source: menuConfig?.newsletter?.source ?? "footer",
                }).then((response) => {
                  if (response.success === true) {
                    toastLauncher({
                      text: response.message,
                      render: ({ text, onClose: onCloseToast }) => {
                        return (
                          <Toast onCloseToast={onCloseToast} paddingX={9}>
                            {text ?? "Success"}
                          </Toast>
                        );
                      },
                      props: {
                        duration: 9000,
                        isClosable: true,
                      },
                    });
                  }
                  actions.setSubmitting(false);
                });
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <Stack
                      spacing="4"
                      direction={{ base: "column", sm: "row" }}
                      alignItems={"center"}
                    >
                      <Field
                        fieldContent={{
                          name: "checkout.email",
                          placeholder: menuConfig?.newsletter?.placeholder,
                          errors: {},
                          inputProps: {
                            type: "email",
                            required: "required",
                            autoCorrect: "off",
                            autoCapitalize: "off",
                            inputMode: "email",
                            autoComplete: "email",
                            borderColor: "#1111112F",
                          },
                        }}
                        validate={(value) => {
                          if (!value) {
                            return "Required";
                          }
                        }}
                      />
                      <Button
                        type="submit"
                        paddingX={9}
                        paddingY={5}
                        width={{ base: "100%", sm: "fit-content" }}
                        isLoading={isSubmitting}
                      >
                        {menuConfig?.newsletter?.cta}
                      </Button>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>

            {process.env.NODE_ENV === "development" && menuConfig?.modals && (
              <Stack spacing="3" direction="row">
                {menuConfig?.modals.map((button, index) => (
                  <Button
                    key={index}
                    variant="outline"
                    width="fit-content"
                    onClick={() => window.launchModal(button.identifier)}
                    size="xs"
                  >
                    {button.label}
                  </Button>
                ))}
              </Stack>
            )}
          </Stack>
          <Stack spacing="4">
            <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
              {menuConfig?.social?.caption}
            </Text>
            <ButtonGroup variant="text">
              {(menuConfig?.social?.links ?? []).map((social, index) => {
                let ThisIcon = FaLink;
                switch (social.icon) {
                  case "instagram":
                    ThisIcon = FaInstagram;
                    break;
                  case "facebook":
                    ThisIcon = FaFacebook;
                    break;
                  case "github":
                    ThisIcon = FaGithub;
                    break;
                  case "linkedin":
                    ThisIcon = FaLinkedin;
                    break;
                  case "x":
                    ThisIcon = FaXTwitter;
                    break;
                  case "tiktok":
                    ThisIcon = FaTiktok;
                    break;
                  default:
                    ThisIcon = FaLink;
                    break;
                }
                return (
                  <IconButton
                    key={index}
                    as="a"
                    href={social?.url}
                    aria-label={social?.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={<ThisIcon size={24} />}
                  />
                );
              })}
            </ButtonGroup>
            <Button
              onClick={onOpen}
              variant="outline"
              width="fit-content"
              paddingX={3}
              paddingY={4}
            >
              <IconWorld boxSize="16px" marginRight={3} />
              {countries[localization.country] &&
                countries[localization.country].name}
              <IconDrop boxSize="8px" marginLeft={3} />
            </Button>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Container>
  );
};
