import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { useEcommerce } from "context/EcommerceContext";

export const VATNumber = ({ content, onClose }) => {
  const { cart, updateOrderInformation } = useEcommerce();

  return (
    <Formik
      initialValues={{ vatnumber: cart?.vatnumber ?? "" }}
      onSubmit={(values, actions) => {
        updateOrderInformation({
          part: "vat",
          vatnumber: values.vatnumber ?? "",
        }).then((response) => {
          actions.setSubmitting(false);
          if (response?.errors?.children?.vatnumber?.errors?.length > 0) {
            const errorName =
              response?.errors?.children?.vatnumber?.errors[0].replace(
                "form.vatnumber.",
                ""
              );
            const errorString = content?.field?.errors[errorName] ?? "Error";
            actions.setFieldError("vatnumber", errorString);
          } else {
            onClose();
          }
        });
      }}
    >
      {(props) => (
        <Form>
          <Field part="vat" name="vatnumber">
            {({ field, form }) => {
              return (
                <FormControl
                  isInvalid={form.errors.vatnumber && form.touched.vatnumber}
                >
                  <FormLabel></FormLabel>
                  <Flex gap={3}>
                    <Input
                      minHeight={12}
                      {...field}
                      placeholder={content?.field?.placeholder}
                    />
                    <Button
                      variant="form-inline"
                      isLoading={props.isSubmitting}
                      transitionDelay={0}
                      type="submit"
                    >
                      {content?.cta}
                    </Button>
                  </Flex>
                  <FormErrorMessage>{form.errors.vatnumber}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Form>
      )}
    </Formik>
  );
};
