import React from "react";
import he from "he";
import parse from "html-react-parser";
import { Link } from "gatsby";
import convert from "convert-units";
import { addWorkingDays } from "utils/products";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

const keywords = {
  brand: "Smiirl",
};

export const replaceWithPlaceholders = (str, obj) => {
  return str.replace(/%([\w.]+)%/g, (match, key) => {
    return key in obj ? obj[key] : match;
  });
};

const processCurrency = (text, { localizations }) => {
  text = text.replace(
    /%currency.00:([a-zA-Z]{3}):(-?[\d.]+)%/g, // Updated regex to support negative floats
    (match, currencyCode, amount) => {
      const value = parseFloat(amount);

      if (isNaN(value)) return match;

      const formatter = new Intl.NumberFormat(localizations.locale, {
        style: "currency",
        currency: currencyCode.toUpperCase(),
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    }
  );
  return text.replace(
    /%currency:([a-zA-Z]{3}):(-?[\d.]+)%/g, // Updated regex to support negative floats
    (match, currencyCode, amount) => {
      const value = parseFloat(amount);
      const valueInt = parseInt(amount);
      const isRounded = value === valueInt;

      if (isNaN(value)) return match;

      const formatter = new Intl.NumberFormat(localizations.locale, {
        style: "currency",
        currency: currencyCode.toUpperCase(),
        minimumFractionDigits: isRounded ? 0 : 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    }
  );
};

const processNumber = (text, { localizations }) => {
  return text.replace(
    /%number:(-?[\d.]+):(-?[\d.]+)%/g, // Updated regex to support negative floats
    (match, fractionDigits, number) => {
      const value = parseFloat(number);

      if (isNaN(value)) return match;

      const formatter = new Intl.NumberFormat(localizations.locale, {
        style: "decimal",
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      });
      return formatter.format(value);
    }
  );
};

const shortUnits = {
  // Length
  mm: "millimeter",
  cm: "centimeter",
  m: "meter",
  km: "kilometer",
  mi: "mile",
  in: "inch",
  ft: "foot",

  // Mass
  mg: { long: "milligram", short: "mg" },
  g: "gram",
  kg: "kilogram",
  lb: "pound",
  oz: "ounce",

  // Volume
  ml: "milliliter",
  l: "liter",
  "fl-oz": "fluid-ounce",
  gal: "gallon",

  // Temperature
  "°C": "celsius",
  "°F": "fahrenheit",

  // Percentage
  "%": "percent",

  // Area
  mm2: { long: "square millimeters", short: "mm²" },
  cm2: { long: "square centimenters", short: "cm²" },
  m2: { long: "square inches", short: "m²" },
  km2: { long: "square kilometers", short: "km²" },
  in2: { long: "square inches", short: "in²" },
  ft2: { long: "square feets", short: "ft²" },
  mi2: { long: "square miles", short: "mi²" },
  ac: "acre",
  ha: "hectare",
};

const needConversion = {
  US: {
    // Length conversions
    mm: "in",
    cm: "in",
    m: "ft",
    km: "mi",

    // Mass conversions
    mg: "oz",
    g: "oz",
    kg: "lb",

    // Volume conversions
    ml: "fl-oz",
    l: "gal",

    // Temperature conversions
    "°C": "°F",

    // Area conversions
    mm2: "in2",
    cm2: "in2",
    m2: "ft2",
    km2: "mi2",
    ha: "ac",
  },
  UK: {
    km: "mi",
    stone: "lb",
    km2: "mi2",
    ha: "ac",
  },
};

const processUnit = (text, { localizations }) => {
  return text.replace(/%unit:(\S+?):([\d.]+)%/g, (match, unit, value) => {
    let mode = "default";
    if (unit.includes("-")) {
      [unit, mode] = unit.split("-");
    }
    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) return match;
    let numericValueConverted = numericValue;
    let unitConverted = unit;

    const countryConfig = needConversion[localizations.country] || {};

    // Check if the unit needs conversion
    if (countryConfig[unit]) {
      const fromUnit = unit;
      const toUnit = countryConfig[unit];
      try {
        numericValueConverted = convert(numericValue)
          .from(fromUnit.replace("°", ""))
          .to(toUnit.replace("°", ""));
        unitConverted = toUnit;
      } catch (e) {
        console.error(`Conversion error: ${e.message}`);
        return match;
      }
    }

    let unitDisplay = shortUnits[unit] || unit;
    let unitDisplayConverted = shortUnits[unitConverted] || unitConverted;

    // Check if unitDisplay is an object with long/short formatting options
    if (typeof unitDisplayConverted === "object") {
      // Choose between 'long' or 'short' based on your requirement
      unitDisplayConverted =
        unitDisplayConverted.short || unitDisplayConverted.long; // Default to short
      const formatter = new Intl.NumberFormat(localizations.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: mode === "default" || mode === "only" ? 2 : 0,
      });
      if (mode === "number") {
        return formatter.format(numericValueConverted);
      } else if (mode === "unit") {
        return unitDisplayConverted;
      }
      if (numericValueConverted !== numericValue) {
        return (
          `${formatter.format(numericValueConverted)} ${unitDisplayConverted}` +
          (mode === "default"
            ? ` (${formatter.format(numericValue)} ${unit})`
            : "")
        );
      }
      return `${formatter.format(
        numericValueConverted
      )}  ${unitDisplayConverted}`;
    }

    try {
      const formatter = new Intl.NumberFormat(localizations.locale, {
        style: "unit",
        unit: unitDisplayConverted,
        unitDisplay: "short", // You can use "long" here if desired
        minimumFractionDigits: 0,
        maximumFractionDigits: mode === "default" || mode === "only" ? 2 : 0,
      });
      const formatterClassic = new Intl.NumberFormat(localizations.locale, {
        style: "unit",
        unit: unitDisplay,
        unitDisplay: "short", // You can use "long" here if desired
        minimumFractionDigits: 0,
        maximumFractionDigits: mode === "default" || mode === "only" ? 2 : 0,
      });
      if (mode === "number") {
        const formatterNumberOnly = new Intl.NumberFormat(
          localizations.locale,
          {
            minimumFractionDigits: 0,
            maximumFractionDigits:
              mode === "default" || mode === "only" ? 2 : 0,
          }
        );
        return formatterNumberOnly.format(numericValueConverted);
      } else if (mode === "unit") {
        return formatter.format(0).replace("0", "").trim();
      }
      if (unitDisplayConverted !== unitDisplay) {
        return (
          formatter.format(numericValueConverted) +
          (mode === "default"
            ? ` (${formatterClassic.format(numericValue)})`
            : "")
        );
      }
      return formatter.format(numericValueConverted);
    } catch (e) {
      // Fallback for unsupported units by Intl.NumberFormat
      console.error(
        `Formatting error for unit: ${unit}. Message: ${e.message}`
      );
      return `${numericValue.toFixed(2)} ${unit}`;
    }
  });
};

const processShipping = (text, { localizations, product }) => {
  return text
    .replace("%shipping:date%", () => {
      try {
        const date = addWorkingDays(product?.delivery ?? 3);
        const formatter = new Intl.DateTimeFormat(
          `${localizations?.language}-${localizations?.country}`,
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        );
        return formatter.format(date);
      } catch (e) {}
      return "?";
    })
    .replace("%shipping:country%", () => {
      try {
        return countries.getName(
          localizations?.country,
          localizations?.language
        );
      } catch (e) {}
      return localizations?.country;
    });
};

export const parseText = (text, { localizations, product }) => {
  let processedText = he.decode(text);

  // Replace Simple Keyworkds
  processedText = processedText.replace(/%(\w+)%/g, (match, keyword) => {
    return keywords[keyword] || match;
  });

  // Replace currency placeholders
  processedText = processCurrency(processedText, { localizations, product });

  // Replace unit placeholders
  processedText = processUnit(processedText, { localizations, product });

  // Replace unit placeholders
  processedText = processNumber(processedText, { localizations, product });

  // Replace unit placeholders
  processedText = processShipping(processedText, { localizations, product });

  return processedText;
};

const parseHTML = (html) => {
  return parse(html, {
    replace: (domNode) => {
      if (domNode.name === "a") {
        const { href } = domNode.attribs;
        const content = domNode.children[0]?.data || href;
        if (href.startsWith("http")) {
          return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {content}
            </a>
          );
        }
        return <Link to={href}>{content}</Link>;
      }
    },
  });
};

export const processReactChildren = (
  children,
  { localizations, product } = {}
) => {
  const processChild = (child) => {
    if (typeof child === "string") {
      const processedText = parseText(child, { localizations, product });
      return parseHTML(processedText);
    } else if (React.isValidElement(child)) {
      if (
        child.type === "a" &&
        child.props.href?.startsWith("http") === false
      ) {
        const { href } = child.props;
        const content = child.props.children;
        return <Link to={href}>{content}</Link>;
      }
      return React.cloneElement(child, {
        ...child.props,
        children: React.Children.map(child.props.children, processChild),
      });
    }
    return child;
  };

  return React.Children.map(children, processChild);
};
