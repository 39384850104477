import React, { useState, useCallback, useEffect } from "react";
import {
  Flex,
  Box,
  Button,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { RadioCardGroup } from "components";
import { FiChevronDown } from "react-icons/fi";
import { useAddToCart } from "context/AddToCartContext";
import { textSurchargeWithProduct, parseSlug } from "utils/products";
import { navigate } from "gatsby";

export const PopoverIcon = ({ isOpen = false }) => {
  const iconStyles = {
    transform: isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.3s",
    transformOrigin: "center",
  };
  return (
    <Box marginLeft="4px" width="10px" height="16px">
      <Icon boxSize="16px" aria-hidden as={FiChevronDown} __css={iconStyles} />
    </Box>
  );
};

const PopOverAbility = ({
  isPopover = false,
  isVisible = false,
  hasBackgorundColor = false,
  isSticky = false,
  direction = "right",
  buttonContent,
  onClick,
  children,
  uiConf,

  ...props
}) => {
  if (isPopover === false) {
    return children;
  }
  return (
    <Flex {...uiConf.radioGroupProps} rounded="full" position="relative">
      <Flex
        position="absolute"
        top={10}
        right={direction === "left" ? 0 : undefined}
        left={direction === "left" ? undefined : 0}
        justifyContent={direction === "left" ? "end" : "start"}
        display={isVisible ? "flex" : "none"}
        zIndex={1000}
        width={"75vw"}
        {...props}
      >
        <Box
          rounded="full"
          backgroundColor="#D4D4D47F"
          backdropFilter="blur(9px)"
          color="black"
          zIndex={1000}
        >
          {children}
        </Box>
      </Flex>
      <Button
        variant="toggle"
        bgColor="white"
        color="black"
        isActive={isVisible}
        _hover={{ bgColor: "white", color: "black" }}
        fontSize="sm"
        fontWeight="normal"
        paddingX="12px"
        paddingY={0}
        height="100%"
        rounded="full"
        whiteSpace="nowrap"
        onClick={onClick}
        minHeight="30px"
      >
        {buttonContent}
        <PopoverIcon isOpen={isVisible} />
      </Button>
    </Flex>
  );
};

export const CounterSelector = ({
  mode = "oneline",
  isSticky = false,
  rightComponent = undefined,
  leftComponent = undefined,
  loadPage = undefined,
  ...props
}) => {
  const { product, productStore, setProductReference } = useAddToCart();
  const [selector, setSelector] = useState("none");
  const [navigated, setNavigated] = useState(true);
  const { backgroundColor } = props;
  const iconsName = {
    ig: "IconInstagram",
    fb: "IconFacebook",
    tt: "IconTikTok",
    cu: "IconCustom",
  };

  // Get Counter Family
  const products = productStore.filter(
    (product) => product.status !== "inactivate" && product.family === "counter"
  );

  // Group by Model & Variation
  const groupedProducts = products.reduce((acc, p) => {
    const { model, variation } = p;
    let modelGroup = acc.find((group) => group.model === model);
    if (!modelGroup) {
      modelGroup = { model, variants: [] };
      acc.push(modelGroup);
    }
    if (p?.content["product.selector"]?.lottie) {
      modelGroup.lottie = p?.content["product.selector"]?.lottie;
    }
    modelGroup.variants.push({ variation, product: p });

    return acc;
  }, []);

  // Get Variants
  const groupedProductVariants =
    groupedProducts.find((group) => {
      return group?.model === product.model;
    })?.variants || [];
  // Selector
  const toggleSelector = useCallback((selectorType) => {
    return () => {
      setSelector((current) => {
        return current === selectorType ? "none" : selectorType;
      });
    };
  }, []);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (
      navigated === false &&
      loadPage !== undefined &&
      product?.content?.product?.pages &&
      product?.content?.product?.pages[loadPage]?.slug !== undefined
    ) {
      const productURL = parseSlug(
        product,
        product?.content?.product?.pages[loadPage]?.slug
      );
      if (
        window?.location?.pathname?.replace(/\/$/, "") !==
        productURL.replace(/\/$/, "")
      ) {
        navigate(
          parseSlug(product, product?.content?.product?.pages[loadPage]?.slug)
        );
      }
      setNavigated(true);
    }
  }, [product, loadPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const counterTypeOptions = groupedProducts.map((productGroup) => {
    const Icon = require("components/core/icons")[
      iconsName[productGroup.model]
    ];
    return {
      children: (
        <Flex
          alignItems="center"
          minWidth="16px"
          justifyContent="center"
          height="30px"
        >
          <Icon boxSize="16px" />
        </Flex>
      ),
      lottie: productGroup.lottie,
      value: productGroup.model,
    };
  });

  const counterVariantOptions = groupedProductVariants.map((productVariant) => {
    return {
      children: (
        <Text height="30px" lineHeight="30px">
          {textSurchargeWithProduct(
            productVariant?.product,
            productVariant?.product?.content?.product?.titles?.variation?.long
          )}
        </Text>
      ),
      value: productVariant.variation,
    };
  });

  const radioGroupProps = {
    padding: "2px",
    backgroundColor: "#1A1A1A1A",
  };

  const modeConfiguration = {
    oneline: {
      marginX: 0,
      flexDirection: "row",
      radioGroupProps,
      innerFlexContainer: isSticky
        ? { width: isSticky ? "fit-content" : "100%" }
        : {},
      model: {
        extraComponent: false,
        popOver: false,
        radioGroupProps: {
          ...radioGroupProps,
          boxShadow: "none",
          color: "black",
          width: "fit-content",
        },
      },
      variation: {
        extraComponent: false,
        popOver: isMobile,
        radioGroupProps: {
          ...radioGroupProps,
          boxShadow: isMobile ? "lg" : "none",
        },
      },
    },
    sticky: {
      marginX: "auto",
      flexDirection: isSticky ? "row" : "column",
      leftComponent: isSticky,
      rightComponent: true,
      container: isSticky && isMobile ? { width: "100%" } : {},
      bgContainer: {
        backgroundColor: isSticky ? "#D4D4D47F" : "transparent",
        backdropFilter: isSticky ? "blur(9px)" : "",
        rounded: isSticky && isMobile ? "none" : "full",
      },
      innerContainer:
        isSticky && isMobile
          ? { paddingY: 2, width: "100%", paddingRight: 3 }
          : { width: "100%" },
      innerFlexContainer: isSticky
        ? {
            width: "auto",
            flexGrow: 0,
            justifyContent: "space-between",
          }
        : { width: "100%" },
      model: {
        extraComponent: isSticky,
        popOver: isSticky && isMobile,
        radioGroupProps: {
          ...radioGroupProps,
          boxShadow: isSticky && isMobile ? "lg" : "none",
          width: isSticky ? "fit-content" : "100%",
        },
      },
      variation: {
        extraComponent: true,
        popOver: isSticky,
        radioGroupProps: {
          ...radioGroupProps,
          boxShadow: isSticky && isMobile ? "lg" : "none",
        },
      },
    },
    "sticky-full": {
      marginX: "auto",
      flexDirection: "row",
      leftComponent: true,
      rightComponent: true,
      container: isSticky && isMobile ? { width: "100%" } : {},
      bgContainer: {
        backgroundColor: "#D4D4D47F",
        backdropFilter: "blur(9px)",
        rounded: isSticky && isMobile ? "none" : "full",
      },
      innerContainer: isMobile
        ? { paddingY: 2, width: "100%", paddingRight: 3 }
        : { width: "100%" },
      innerFlexContainer: {
        width: "auto",
        flexGrow: 0,
        justifyContent: "space-between",
      },
      model: {
        extraComponent: true,
        popOver: isMobile,
        radioGroupProps: {
          ...radioGroupProps,
          boxShadow: isMobile ? "lg" : "none",
          width: "fit-content",
        },
      },
      variation: {
        extraComponent: true,
        popOver: isSticky || isMobile,
        radioGroupProps: {
          ...radioGroupProps,
          boxShadow: isMobile ? "lg" : "none",
        },
      },
    },
  };
  const uiConf = modeConfiguration[mode] ?? {};

  return (
    <Flex
      direction={uiConf.flexDirection}
      width="fit-content"
      height="fit-content"
      position="relative"
      {...(uiConf.container ?? {})}
      {...props}
    >
      <Box
        position="absolute"
        width="100%"
        height="100%"
        rounded="full"
        {...(uiConf.bgContainer ?? {})}
      />
      <Flex
        direction={uiConf.flexDirection}
        width="fit-content"
        height="fit-content"
        gap={1}
        padding={rightComponent ? 1 : 0}
        zIndex={1}
        {...(uiConf.innerContainer ?? {})}
      >
        <Flex
          direction="row"
          gap={1}
          width="fit-content"
          {...uiConf.innerFlexContainer}
          flexGrow={1}
        >
          {uiConf.model?.extraComponent && leftComponent}
          <PopOverAbility
            isSticky={isSticky}
            hasBackgorundColor={backgroundColor !== undefined}
            isPopover={uiConf.model?.popOver}
            isVisible={selector === "counterModel"}
            buttonContent={
              counterTypeOptions.find(
                (option) => option.value === product.model
              )?.children
            }
            onClick={toggleSelector("counterModel")}
            uiConf={uiConf}
          >
            <RadioCardGroup
              name="counterModel"
              defaultValue={product.model}
              value={product.model}
              options={counterTypeOptions}
              onChange={(value) => {
                setNavigated(false);
                setProductReference(
                  `${product.family}_${value}_${product.variation}`.toLowerCase()
                );
                setSelector("none");
              }}
              {...uiConf.model?.radioGroupProps}
            />
          </PopOverAbility>
        </Flex>
        <Flex direction="row" gap={1} {...uiConf.innerFlexContainer}>
          <PopOverAbility
            isSticky={isSticky}
            hasBackgorundColor={backgroundColor !== undefined}
            isPopover={uiConf.variation?.popOver}
            direction="left"
            isVisible={selector === "counterSize"}
            buttonContent={textSurchargeWithProduct(
              product,
              product?.content?.product?.titles?.variation?.tiny
            )}
            onClick={toggleSelector("counterSize")}
            uiConf={uiConf}
          >
            <RadioCardGroup
              name="counterSize"
              defaultValue={product.variation}
              value={product.variation}
              options={counterVariantOptions}
              width="fit-content"
              overflow="hidden"
              onChange={(value) => {
                setNavigated(false);
                setProductReference(
                  `${product.family}_${product.model}_${value}`.toLowerCase()
                );
                setSelector("none");
              }}
              {...uiConf.variation?.radioGroupProps}
            />
          </PopOverAbility>
          {uiConf.variation?.extraComponent && rightComponent}
        </Flex>
      </Flex>
    </Flex>
  );
};
