import React from "react";
import { Text } from "components";
import { Flex, Box } from "@chakra-ui/react";
import { convertToArray } from "utils/object";
import { TrustpilotBox } from "./TrustpilotBox";

export const ReassuranceBanner = ({
  product,
  mode = "addToCart",
  ...props
}) => {
  const items = convertToArray(
    product?.content["addToCart.modale"]?.reassurance || []
  );
  if (items.length === 0) {
    return null;
  }

  return (
    <Flex
      borderY={mode === "addToCart" ? "solid 1px #8D8D8D" : "none"}
      backgroundColor={mode === "cart" ? "smiirl.200" : "transparent"}
      rounded={mode === "cart" ? "md" : "none"}
      paddingX={mode === "cart" ? 4 : 0}
      flexDirection={{ base: "column", md: "row" }}
      width="100%"
      height="100%"
      alignItems={{ base: "center", md: "stretch" }}
      justifyContent="space-between"
      gap={{ base: 4, md: 0 }}
      paddingY={3}
    >
      {items.map((item, index) => {
        if (item.icon !== undefined) {
          const Icon =
            require("components/core/icons")[item.icon] ??
            require("components/core/icons")["IconSend"];
          return (
            <Flex
              key={index}
              overflow="hidden"
              height="100%"
              justifyContent="flex-start"
              alignItems="center"
              gap={2}
            >
              <Icon boxSize="20px" />
              <Text variant="reassurance">{item.text}</Text>
            </Flex>
          );
        } else if (item.trustpilot !== undefined) {
          return (
            <Flex
              key={index}
              justifyContent="center"
              alignItems="center"
              minWidth="90px"
              position="relative"
            >
              <Box
                transform={
                  mode === "cart"
                    ? "translateY(-50%) scale(0.5)"
                    : "translateY(-50%) scale(0.66666)"
                }
                position={"absolute"}
                top="50%"
              >
                <TrustpilotBox {...(item.trustpilot ?? {})} />
              </Box>
            </Flex>
          );
        }
        return <Box key={index}>?</Box>;
      })}
    </Flex>
  );
};
