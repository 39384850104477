import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  baseStyle: defineStyle({
    borderRadius: "full",
    fontWeight: "600",
    paddingY: 1,
    _focusVisible: {
      boxShadow: "none",
    },
  }),
  variants: {
    toggle: {
      color: "smiirl.700",
      rounded: "xl",
      fontSize: "0.96em",
      background: "transparent",
      paddingX: 5,
      height: "auto",
      paddingY: "0.65em",
      _hover: {
        background: "smiirl.100",
      },
      _focusVisible: {
        background: "smiirl.100",
      },
      _active: {
        color: "smiirl.900",
        background: "white",
        boxShadow: "md !important",
      },
    },
    solid: {
      color: "white",
      background: "smiirl.900",
      _hover: {
        color: "white",
        background: "smiirl.850",
      },
      _active: {
        color: "white",
        background: "smiirl.800",
      },
    },
    "solid-full": {
      color: "white",
      width: "100%",
      background: "smiirl.900",
      rounded: 12,
      fontSize: "md",
      paddingY: 7,
      _hover: {
        color: "white",
        background: "smiirl.850",
      },
      _active: {
        color: "white",
        background: "smiirl.800",
      },
    },
    outline: {
      borderWidth: "1px",
      borderColor: "smiirl.900",
      color: "smiirl.900",
      _hover: {
        color: "white",
        background: "smiirl.850",
      },
      _active: {
        color: "white",
        background: "smiirl.900",
      },
    },
    link: {
      color: "smiirl.900",
    },
    "solid-inverted": {
      color: "black",
      background: "white",
      _hover: {
        color: "black",
        background: "whiteAlpha.900",
      },
      _active: {
        color: "blackAlpha.900",
        background: "whiteAlpha.900",
      },
    },
    "outline-inverted": {
      borderWidth: "1px",
      borderColor: "white",
      color: "white",
      _hover: {
        color: "black",
        background: "white",
      },
      _active: {
        color: "white",
        background: "smiirl.900",
      },
    },
    "ghost-inverted": {
      color: "whiteAlpha.700",
      _hover: {
        background: "whiteAlpha.300",
      },
      _active: {
        color: "whiteAlpha.900",
        background: "whiteAlpha.300",
      },
    },
    "form-inline": {
      color: "white",
      background: "smiirl.900",
      rounded: "md",
      height: "auto",
      minWidth: "160px",
      _active: {
        color: "white",
        background: "smiirl.800",
      },
      _hover: {
        color: "white",
        background: "smiirl.800",
        _disabled: {
          background: "smiirl.900",
        },
      },
    },
  },

  // The default size and variant values*/
  defaultProps: {
    size: "sm",
    variant: "solid",
    colorScheme: "smiirl",
  },
});
